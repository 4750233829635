import React, { useState, useEffect, useRef } from 'react'

import { Link, useNavigate, useLocation } from 'react-router-dom'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import update from 'immutability-helper'
import { Tooltip } from '@mui/material'
import { useAuth } from '../../hooks/auth'

import AppLayout from '../Layouts/AppLayout'
import { useTranslation } from 'react-i18next'
import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import '../../ShipmentCalendar.css'
import Loading from '../Loading'
import Navigation from './Navigation'
import i18next from 'i18next'
import allLocales from '@fullcalendar/core/locales-all'
import { isValid } from '../../helpers/helper'
import { buildUrl } from '../../helpers/apiHelper'
import EventItems from '../common/calendar/EventItems'

const Calendar = () => {

    const { user } = useAuth({ middleware: 'guest ' })

    const { t } = useTranslation()
    const [orders, setOrders] = useState([])
    const { config, choosesite, setChoosesite, pusher } = useStateContext()
    const calendarRef = useRef([])
    const navigate = useNavigate()
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(false)

    const [events, setEvents] = useState([])

    useEffect(() => {
        if (isValid(choosesite)) {
            getOrders(choosesite)
        }
    }, [choosesite])

    const getOrders = async (site) => {
        setEvents([])
        setIsLoading(true)

        const url = buildUrl(
            '/api/list-orders',
            {
                where: {
                    'order_type': 'sales',
                    'site_id': site,
                    'pending': !localStorage.getItem('client_id').includes('bramptonpallet') ? true : ''
                }
            }
        )

        await axios.get(url, config)
            .then(res => {
                const orders = res.data?.data.filter((i) => {
                    const upTo = !localStorage.getItem('client_id').includes('bramptonpallet') ? 9 : 10;
                    return i.so_status_id < upTo || i.so_status_id === 11;
                });
                setOrders(orders)
                setIsLoading(false)
            })
    }

    useEffect(() => {
        setEvents([])
        orders.map((i) => {
            let new_item = {
                "order_id": i.id,
                "title": i.so_number,
                "date": i.requested_delivery_date + 'T' + i.requested_delivery_time_time,
                "requested_delivery_date": i.requested_delivery_date,
                "customer_name": i.customer_name,
                "ship_address": i.ship_address_name,
                "items": i?.sales_order_items
            }


            setEvents((prev) => [...prev, new_item])
        })
    }, [orders])

    useEffect(() => {
        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${localStorage.getItem('site')}`)
        const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-created-site-${localStorage.getItem('site')}`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${localStorage.getItem('site')}`)

        channeldelete.bind(`${localStorage.getItem('client_id')}-salesorder-deleted-event-site-${localStorage.getItem('site')}`, data => {
            setOrders((prev) => {
                const item = prev.find((i) => i.id === data.id)
                const exItem = prev.filter((i) => i.id !== item.id)
                return exItem
            })
        })

        channelcreate.bind(`${localStorage.getItem('client_id')}-salesorder-created-event-site-${localStorage.getItem('site')}`, data => {
            getSalesOrder(data.id, 'created')
        })

        channelupdate.bind(`${localStorage.getItem('client_id')}-salesorder-updated-event-site-${localStorage.getItem('site')}`, data => {
            getSalesOrder(data.id, 'updated')
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-created-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${localStorage.getItem('site')}`)
        })
    }, [])

    const getSalesOrder = async (id, state) => {
        setIsLoading(true)
        await axios.get(`/api/sales-orders/${id}`, config)
            .then(res => {
                const salesOrder = res.data
                if (state === 'created') setOrders((prev) => [...prev, salesOrder])

                if (state === 'updated') setOrders((prev) => {

                    const index = prev?.findIndex((i) => i.id === id)
                    if (index < 0) {
                        const items = [...prev, salesOrder]
                        return items
                    } else {
                        const finalStatus = localStorage.getItem('client_id').includes('bramptonpallet') ? 'invoiced' : 'shipped';

                        if (salesOrder.so_status === finalStatus) {
                            return update(prev, { $splice: [[index, 1]] })
                        } else {
                            return update(prev,
                                { [index]: { $set: salesOrder } }
                            )
                        }
                    }
                })
            })
            .catch(({ response }) => console.error(response))
            .finally(() => {
                setIsLoading(false)
            })

    }

    const handleEventClick = (eventInfo) => {
        localStorage.setItem('initialDateSales', eventInfo.event.extendedProps.requested_delivery_date)
        navigate(`/sales-order/${eventInfo.event.extendedProps.order_id}`, { state: { prevPathname: location.pathname } });
    }


    return (
        <>
        {isLoading ? <Loading /> : null}
            <AppLayout>
                <Navigation route='sales-order' type='calendar' user={user} />
                <div className='p-5 w-full'>
                    <div className='p-2 bg-white mb-2 rounded-md shipment'>
                        <FullCalendar
                            locales={allLocales}
                            locale={i18next?.resolvedLanguage ?? 'en'}
                            ref={calendarRef}
                            plugins={[dayGridPlugin, timeGridPlugin]}
                            headerToolbar={{
                                left: 'prev,next today',
                                center: 'title',
                                right: 'dayGridMonth,timeGridWeek,timeGridDay'
                            }}
                            buttonText={{
                                today: t('today'),
                                month: t('month'),
                                week: t('week'),
                                day: t('day')
                            }}
                            customButtons={{
                                today: {
                                    text: t('today'),
                                    click: () => {
                                        localStorage.removeItem('initialDateSales')
                                        let calendarApi = calendarRef.current.getApi()
                                        calendarApi.today()
                                    }
                                }
                            }}
                            initialView='dayGridMonth'
                            events={events}
                            eventContent={RenderEventContent}
                            dayMaxEvents={true}
                            eventMaxStack={1}
                            expandRows={true}
                            eventColor='#ffffff'
                            initialDate={localStorage.getItem('initialDateSales')}
                            eventClick={handleEventClick}
                        />
                    </div>
                </div>
            </AppLayout>
        </>
    )
}

export default Calendar

const RenderEventContent = (eventInfo) => {

    const { items, order_id } = eventInfo.event.extendedProps;

    return (
        <Tooltip disableInteractive  placement='left' title={
            <div className='flex flex-col'>
              <p className='text-[16px] pb-3'>{eventInfo.event.title}</p>
              <i className='text-[14px]'>{eventInfo.event.extendedProps.customer_name}</i>
              <i className='text-[14px]'>{eventInfo.event.extendedProps.ship_address}</i>
              <EventItems items={items} orderId={order_id} orderType="sales" />
            </div>
          }>
            <button className='w-full'>
                <div className='p-1 flex flex-col text-left bg-[#336195] text-white rounded-sm'>
                    <p className='text-xl bold p-1 roboto'>{eventInfo.event.title}</p>
                    <i>{eventInfo.event.extendedProps.customer_name}</i>
                    <i>{eventInfo.event.extendedProps.ship_address}</i>
                    <EventItems items={items} orderId={order_id} orderType="sales" />
                </div>
            </button>
        </Tooltip>
    )
}
