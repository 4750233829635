import React, { useState, useEffect } from 'react'

import { useNavigate, useParams } from "react-router-dom"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axios from '../../../lib/axios'
import Swal from 'sweetalert2'
import { Box, TextField, FormControl, InputLabel, Select, IconButton, MenuItem, FormHelperText, ListItemText, ListItemIcon, Checkbox, Chip } from '@mui/material'
import { deleteZero, handleNumber, handleZero, isValid } from '../../../helpers/helper'
import ClearIcon from "@mui/icons-material/Clear"
import { PAYMENT_METHODS, SELECT_STYLE, TEXTFIELD_STYLE } from '../../../constants/constants'

import Loading from '../../../components/Loading'
import { useStateContext } from '../../../context/ContextProvider'
import { useAuth } from '../../../hooks/auth'

const UpdateProductionEmployee = () => {

    const { user } = useAuth({ middleware: 'guest ' })

    const { t } = useTranslation()
    const { companies, sites, getSites, config, currencySymbol } = useStateContext()
    const params = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    const [companyProductionTypes, setCompanyProductionTypes] = useState([])
    const [typeOptions, setTypeOptions] = useState([])

    /* Fields */
    const [no, setNo] = useState('')
    const [name, setName] = useState('')
    const [companyId, setCompanyId] = useState('')
    const [siteId, setSiteId] = useState('')
    const [productionTypes, setProductionTypes] = useState([])
    const everyProductionTypeSelected = companyProductionTypes.length > 0 && productionTypes.length === companyProductionTypes.length

    const [paymentMethod, setPaymentMethod] = useState('')
    const [rate, setRate] = useState(0)

    const [errorNo, setErrorNo] = useState(false)
    const [errorName, setErrorName] = useState(false)
    const [errorSite, setErrorSite] = useState(false)
    const [errorCompany, setErrorCompany] = useState(false)
    const [errorProductionTypes, setErrorProductionTypes] = useState(false)

    const [openProductionTypes, setOpenProductionTypes] = useState(false)

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '27') {
                navigate(-1)
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    useEffect(() => {
        getProductionEmployee(params.id)
    }, [params.id])

    useEffect(() => {
        if (companyId) {
            getProductionTypes(companyId)
            getSites(companyId)
        }
    }, [companyId])

    useEffect(() => {
        setTypeOptions(companyProductionTypes)
    }, [companyProductionTypes, productionTypes])

    const getProductionEmployee = async (id) => {
        setIsLoading(true)
        await axios.get(`/api/production-employees/${id}?with=production_types`, config)
            .then(res => {
                const productionEmployee = res.data
                setNo(productionEmployee.production_employee_no)
                setName(productionEmployee.name)
                setProductionTypes(productionEmployee.production_types)
                setCompanyId(productionEmployee.company_id)
                setSiteId(productionEmployee.site_id)
                setPaymentMethod(productionEmployee?.payment_method)
                setRate(productionEmployee?.rate)
            })
            .finally(() => setIsLoading(false))
    }

    const getProductionTypes = async (cid) => {
        setIsLoading(true)
        await axios.get(`/api/production-types?company_id=${cid}`, config)
            .then(res => {
                const types = res.data
                setCompanyProductionTypes(types)
            })
            .finally(() => setIsLoading(false))
    }

    const updateProductionEmployee = async (e) => {
        e.preventDefault();

        /* if (!no) {
            setErrorNo(true)
        } else */ if (!name) {
            setErrorName(true)
        } else if (!companyId) {
            setErrorCompany(true)
        } else if (!siteId) {
            setErrorSite(true)
        } else if (productionTypes.length < 1) {
            setErrorProductionTypes(true)
        } else {
            setIsLoading(true)

            const formData = {}

            /* formData['production_employee_no'] = no */
            formData['name'] = name
            formData['company_id'] = companyId
            formData['site_id'] = siteId
            formData['production_types'] = productionTypes

            formData['payment_method'] = paymentMethod
            formData['rate'] = rate

            await axios.put(`/api/production-employees/${params.id}`, formData, config).then(({ data }) => {

                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                setIsLoading(false)
            }).catch(({ response }) => {
                if (response.status === 422) {
                    Swal.fire({
                        text: response.data.message,
                        icon: "error",
                        customClass: 'error',
                        showCloseButton: true,
                        iconColor: '#FF0000'
                    })
                } else {
                    Swal.fire({
                        text: response.data.message,
                        icon: "error",
                        customClass: 'error',
                        showCloseButton: true,
                        iconColor: '#FF0000'
                    })
                }
                setIsLoading(false)
            })
        }
    }

    const handleClearCompanyClick = () => {
        setCompanyId('')
        setErrorCompany(false)
        setProductionTypes([])
        setErrorProductionTypes(false)
        setSiteId('')
        setErrorSite(false)
    }

    const handleClearSiteClick = () => {
        setSiteId('')
        setErrorSite(false)
    }

    const handleChangeCompany = (event) => {
        setCompanyId(event.target.value)
        setErrorCompany(false)
        setProductionTypes([])
        setSiteId('')
        setErrorSite(false)
        getSites(event.target.value)
    }

    const handleChangeSite = (event) => {
        setSiteId(event.target.value)
        setErrorSite(false)
    }

    const handleChangeTypes = (event, values) => {
        const { target: { value } } = event
        let duplicateRemoved = []
        value.forEach((type) => {
            if (duplicateRemoved.findIndex((o) => o.id === type.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === type.id)
            } else {
                duplicateRemoved.push(type)
            }
        })

        if (errorProductionTypes) setErrorProductionTypes(false)

        if (value[value.length - 1] === 'all') {
            setProductionTypes(productionTypes.length === typeOptions.length ? [] : typeOptions)
            return
        }

        setProductionTypes(duplicateRemoved)
    }

    const handleDeleteSelection = (e, id) => {
        const shouldDelete = productionTypes.find((x) => x.id === id)
        if (shouldDelete) {
            const filtered = productionTypes.filter((x) => x.id !== shouldDelete.id)
            if (filtered.length > 0) {
                setProductionTypes(filtered)
            } else {
                setProductionTypes([])
                setOpenProductionTypes(false)
            }
        }
    }

    const handleClearPaymentMethod = () => {
        setPaymentMethod('')
        setRate(0)
    }

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <Box>
                <div className='flex border-b justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-center justify-between w-full leading-none'>
                        <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#336195] text-[35px] mr-4'>•</span> {t('update_production_employee')} - {name}</p>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                            <Link to="/production-employees"><i className="fa-solid fa-plus"></i></Link>
                        </div>
                    </div>
                </div>
                <div className='w-full bg-white h-full'>
                    <div className='bg-white p-6 w-full h-fit'>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('no')} *</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" className='w-full' name="production_employee_no" value={no} onChange={(e) => { setNo(e.target.value); setErrorNo(false) }} disabled />
                                {errorNo ? (
                                    <FormHelperText>{t('please_enter_the_x', { x: t('no') })}</FormHelperText>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('name')} *</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" className='w-full' name="drop_trailer_no" value={name} onChange={(e) => { setName(e.target.value); setErrorName(false) }} />
                                {errorName ? (
                                    <FormHelperText>{t('please_enter_the_x', { x: t('name') })}</FormHelperText>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>

                        {(user?.role !== 'production_supervisor' && user?.role !== 'office_manager') ? (

                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('company')} *</p>
                                <div className='w-full'>
                                    <FormControl sx={{ width: '100%' }}>
                                        <Select value={companyId} onChange={handleChangeCompany}
                                            sx={{
                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                            }}>
                                            {companies.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>)}
                                        </Select>
                                        {errorCompany ? (
                                            <FormHelperText>{t('please_choose_the_x', { x: t('company') })}</FormHelperText>
                                        ) : (
                                            ''
                                        )}
                                    </FormControl>

                                </div>
                            </div>
                        ) : ''}
                        {user?.role !== 'production_supervisor' ? (
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('site')} *</p>
                                <div className='w-full'>
                                    <FormControl sx={{ width: '100%' }}>
                                        <Select
                                            disabled={!(companyId && companyId !== '')}
                                            value={siteId}
                                            onChange={e => { setSiteId(e.target.value); setErrorSite(false) }}
                                            sx={{
                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                            }}>
                                            {(companyId !== "" && companyId) ?
                                                sites.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>)
                                                : ''}
                                        </Select>
                                        {errorSite ? (
                                            <FormHelperText>{t('please_choose_the_x', { x: t('site') })}</FormHelperText>
                                        ) : (
                                            ''
                                        )}
                                    </FormControl>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}


                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('production_type')} *</p>
                            <div className='w-full'>
                                <FormControl sx={{ width: '100%' }}>
                                    <Select
                                        open={openProductionTypes}
                                        onOpen={() => setOpenProductionTypes(true)}
                                        onClose={() => setOpenProductionTypes(false)}
                                        disabled={!((user?.role !== 'production_supervisor' || user?.role !== 'office_manager') && (companyId && companyId !== ''))}
                                        multiple
                                        value={productionTypes}
                                        onChange={handleChangeTypes}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((x) => (
                                                    <Chip
                                                        key={`pi_option-${x.id}`}
                                                        label={x.code}
                                                        onMouseDown={(e) => e.stopPropagation()}
                                                        onDelete={(e) => handleDeleteSelection(e, x.id)}
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}>
                                        {
                                            companyProductionTypes?.length > 0 ?
                                                <MenuItem value='all'>
                                                    <ListItemIcon>
                                                        <Checkbox checked={everyProductionTypeSelected} indeterminate={productionTypes.length > 0 && productionTypes.length < companyProductionTypes.length} />
                                                    </ListItemIcon>
                                                    <ListItemText primary={t('select_all')} />
                                                </MenuItem>
                                                :
                                                <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                        }
                                        {
                                            companyProductionTypes.map((type) =>
                                                <MenuItem value={type} key={`pt${type.id}`}>
                                                    <Checkbox checked={productionTypes?.findIndex((i) => i.id === type.id) >= 0} />
                                                    <ListItemText primary={type.code} />
                                                </MenuItem>
                                            )
                                        }
                                    </Select>
                                    {errorProductionTypes ? (
                                        <FormHelperText>{t('please_choose_at_least_x_y', {
                                            x: 1,
                                            y: t('production_type')
                                        })}</FormHelperText>
                                    ) : (
                                        ''
                                    )}
                                </FormControl>
                            </div>
                        </div>

                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('payment_method')}</p>
                            <div className='w-full'>
                                <FormControl sx={{ width: '100%' }}>
                                    <Select
                                        value={paymentMethod}
                                        onChange={e => setPaymentMethod(e.target.value)}
                                        sx={SELECT_STYLE}
                                        endAdornment={isValid(paymentMethod) ? (<IconButton sx={{ padding: '0' }} onClick={handleClearPaymentMethod}><ClearIcon /></IconButton>) : undefined}
                                    >
                                        {
                                            PAYMENT_METHODS.map((pm, index) =>
                                                <MenuItem key={`pm-${index}`} value={pm.name}>{t(pm.name)}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        {isValid(paymentMethod) && (
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('rate')} ({currencySymbol}) *</p>
                                <TextField
                                    sx={TEXTFIELD_STYLE}
                                    type="text"
                                    name="rate"
                                    value={rate}
                                    disabled={paymentMethod == ''}
                                    onChange={(e) => handleNumber({
                                        event: e,
                                        setter: setRate,
                                        precision: 2
                                    })}
                                    onBlur={e => handleZero({
                                        event: e,
                                        setter: setRate
                                    })}
                                    onFocus={e => deleteZero({
                                        event: e,
                                        setter: setRate,
                                        value: rate
                                    })}
                                />
                            </div>
                        )}

                        <div className='w-full flex justify-end px-6 pb-14'>
                            <button type="button" onClick={updateProductionEmployee} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#015D9F] hover:bg-[#003459] disabled:bg-[#B8B7BC]'>
                                <span className='pr-2'><i className="fa-solid fa-clock-rotate-left"></i></span> {t('update')}
                            </button>
                        </div>
                    </div>
                </div>
            </Box>
        </>
    )
}

export default UpdateProductionEmployee
