import React, {useEffect, useState} from 'react'

import {useTranslation} from 'react-i18next'
import {Autocomplete, Box, FormControl, MenuItem, Modal, Select, TextField, Tooltip, Stack} from '@mui/material'
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DemoContainer} from '@mui/x-date-pickers/internals/demo'
import {MobileTimePicker} from '@mui/x-date-pickers/MobileTimePicker'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import {useNavigate, useLocation} from "react-router-dom"
import MenuBookIcon from '@mui/icons-material/MenuBook'

import style from '../../styles/style'
import {useStateContext} from '../../context/ContextProvider'
import axios from '../../lib/axios'
import TableShared from '../TableShared'
import AddButton from '../AddButton'
import AddSalesOrderItem from './AddSalesOrderItem'
import { canViewPrice, createRegexFromString, deleteZero, handleAxiosError, handleInteger, handleZero, isValid } from '../../helpers/helper'
import { includes, toNumber } from 'lodash';
import { useAuth } from '../../hooks/auth';
import { TEXTFIELD_STYLE } from '../../constants/constants';

const CreateSalesOrder = ({ open, handleOpen, setIsLoading, type = 'SHIPMENT' }) => {

    const { user } = useAuth({ middleware: 'guest' })
    const { t } = useTranslation()
    const { company_id, config, choosesite, currencySymbol, features } = useStateContext()
    const navigate = useNavigate()
    const location = useLocation()

    /* state */
    const [customers, setCustomers] = useState([])
    const [shipToLocations, setShipToLocation] = useState([])
    const [tplVendors, setTplVendors] = useState([])
    const [brokerVendors, setBrokerVendors] = useState([])
    const [salesOrderItems, setSalesOrderItems] = useState([])
    const [openSalesOrderItem, setOpenSalesOrderItem] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalQty, setTotalQty] = useState(0)
    const [disabled, setDisabled] = useState(true)
    const [disabledButton, setDisabledButton] = useState(true)

    const [headerTextKey, setHeaderTextKey] = useState('create_new_so')

    /* form */
    const [customerId, setCustomerId] = useState('')
    const [shipToAddressId, setShipToAddressId] = useState('')
    const [address, setAddress] = useState('')
    const [zip, setZip] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [deliveryInstructions, setDeliveryInstructions] = useState('')
    const [requestedDeliveryDate, setRequestedDeliveryDate] = useState(dayjs().endOf('day'))
    const [requestedDeliveryTime, setRequestedDeliveryTime] = useState(dayjs().endOf('minute'))
    const [shippedDate, setShippedDate] = useState(dayjs().endOf('day'))
    const [customerPoNo, setCustomerPoNo] = useState('')
    const [pickupTrailerNo, setPickupTrailerNo] = useState('')
    const [dropTrailerNo, setDropTrailerNo] = useState('')
    const [comments, setComments] = useState('')
    const [SCItems, setSCItems] = useState([])
    const [deliveryTicketNo, setDeliveryTicketNo] = useState('')
    const [forkliftDriverId, setForkliftDriverId] = useState('')
    const [tplVendorId, setTplVendorId] = useState('')
    const [brokerVendorId, setBrokerVendorId] = useState('')
    const [loadedDate, setLoadedDate] = useState(dayjs())


    /* KEY EVENT */
    useEffect(() => {
        function handleKeyDown(e) {
            if (e.key == 'Enter' && open) {
                createSalesOrder()
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [
        open,
        customerId,
        shipToAddressId,
        dropTrailerNo,
        customerPoNo,
        requestedDeliveryDate,
        requestedDeliveryTime,
        shippedDate,
        choosesite,
        salesOrderItems,
        comments,
        forkliftDriverId,
        tplVendorId,
        brokerVendorId,
        deliveryTicketNo
    ])


    /* UseEffect */

    useEffect(() => {
        if (open) {
            if (isValid(company_id)) {
                getCustomers(company_id)
                switch(type) {
                    case "3PL":
                        getTplVendors(company_id)
                        break
                    case "BROKERED":
                        getBrokerVendors(company_id)
                        break
                    default:
                }
            }
        }
    }, [company_id, open, type])

    useEffect(() => {
        if (isValid(customerId)) {
            getShipToLocations(customerId)
        }
        setSalesOrderItems([])
    }, [customerId])

    useEffect(() => {
        setSalesOrderItems([])
        if (shipToLocations.length > 0 && customerId && shipToAddressId) {
            getSalesContractItems()
        }
    }, [shipToAddressId])

    useEffect(() => {
        if (SCItems.length > 0) {
            handleNewItem(SCItems)
        }
    }, [SCItems])

    useEffect(() => {
        handleTotalPrice()
        handleTotalQty()
    }, [salesOrderItems])

    useEffect(() => {
        switch(type) {
            case "CUSTOMER PICKUP":
                setHeaderTextKey('create_new_sales_pickup')
                break
            case "3PL":
                setHeaderTextKey('create_new_3pl')
                break
            case "BROKERED":
                setHeaderTextKey('create_new_brokered_order')
                break
            default:
                setHeaderTextKey('create_new_so')
        }
    }, [type])

    /* Handlers */

    const handleNewItem = (e) => {
        e.map((item) => {
            let new_item = {
                "id": item.id,
                "item_no": item.item_no,
                "description": item.description,
                "qty_ordered": item.quantity,
                "qty_to_ship": 0,
                "qty_shipped": 0,
                "qty_loaded": 0,
                "unit_price": item.unit_price,
                "total_cost": item.quantity * item.unit_price,
                "unit_id": item.item_id,
                "color": item.color,
                "unit_of_measure_code": item.unit_of_measure_code
            }

            setSalesOrderItems((prev) => [...prev, new_item])
        })
    }

    const handleTotalPrice = () => {
        const sum = salesOrderItems.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.total_cost), 0);
        setTotalPrice(sum);
    }

    const handleTotalQty = () => {
        const item = salesOrderItems.filter((item) => item.qty_ordered !== "")
        if (item) {
            const sum = item.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.qty_ordered), 0);
            setTotalQty(sum)
        } else {
            setTotalQty(0)
        }
    }

    const handleToggleModal = () => {
        handleOpen()
        setCustomerId('')
        setShipToAddressId('')
        setDropTrailerNo('')
        setPickupTrailerNo('')
        setCustomerPoNo('')
        setDeliveryTicketNo('')
        setComments('')
        setRequestedDeliveryDate(dayjs().endOf('day'))
        setRequestedDeliveryTime(dayjs().endOf('minute'))
        setShippedDate(dayjs().endOf('day'))
        setSalesOrderItems([])
        setForkliftDriverId('')
        setTplVendorId('')
        setBrokerVendorId('')
        setLoadedDate(dayjs())
        setDisabledButton(true)
    }


    const handleCustomerPoNo = (e) => {
        const limit = 50;
        setCustomerPoNo(e.target.value.slice(0, limit));
    }

    const handleShipToLocation = (e) => {
        setShipToAddressId(e.target.value)
        const shipToLocation = shipToLocations.find(target => target.id === e.target.value)
        setAddress(shipToLocation.address)
        setZip(shipToLocation.zip)
        setCity(shipToLocation.city)
        setState(shipToLocation.state)
        setDeliveryInstructions(shipToLocation.delivery_instructions)
    }

    const handleUpdateItem = (id, quantity, unit_price) => {
        const salesOrderItemsCopy = [...salesOrderItems]
        const item = salesOrderItemsCopy.find(item => item.id === id)

        item.qty_ordered = toNumber(quantity)
        item.unit_price = unit_price
        item.total_cost = toNumber(quantity) * unit_price

        setSalesOrderItems(salesOrderItemsCopy)
    }

    const handleUpdateItemQTY = (id, quantity, qts) => {
        const purchaseOrderItemsCopy = [...salesOrderItems]
        const item = purchaseOrderItemsCopy.find(item => item.id === id)

        item.qty_ordered = toNumber(quantity)
        item.qty_to_ship = toNumber(qts)


        setSalesOrderItems(purchaseOrderItemsCopy)
    }

    const handleOpenSalesOrderItem = () => {
        setDisabled(true)
        setOpenSalesOrderItem(!openSalesOrderItem)
    }


    /* API Requests */

    const getBrokerVendors = async (id) => {
        setBrokerVendorId('')
        setIsLoading(true)
        await axios
            .get(`/api/vendors?company_id=${id}&shipping_code=BROKER&blocked=0`, config)
            .then(res => {
                const data = res.data
                setBrokerVendors(data)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getTplVendors = async (id) => {
        setTplVendorId('')
        setIsLoading(true)
        await axios.get(`/api/vendors?company_id=${id}&shipping_code=3PL&blocked=0`, config)
            .then(res => {
                const data = res.data
                setTplVendors(data)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getSalesContractItems = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/sales-contracts?customer_id=${customerId}&ship_address_id=${shipToAddressId}`, config)
            .then(res => {
                const data = res.data
                setSCItems(data[0]?.sales_contract_items || [])
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getCustomers = async (id) => {
        setCustomerId('')
        setIsLoading(true)
        await axios
            .get(`/api/customers?company_id=${id}&blocked=0`, config)
            .then(res => {
                const data = res.data
                setCustomers(data)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getShipToLocations = async (id) => {
        setShipToAddressId('')
        setAddress('')
        setZip('')
        setCity('')
        setState('')
        setDeliveryInstructions('')
        setIsLoading(true)
        await axios
            .get(`/api/ship-addresses?customer_id=${id}`, config)
            .then(res => {
                const data = res.data
                setShipToLocation(data)
                setShipToAddressId(data[0]?.id || '')
                setAddress(data[0]?.address || '')
                setZip(data[0]?.zip || '')
                setCity(data[0]?.city || '')
                setState(data[0]?.state || '')
                setDeliveryInstructions(data[0]?.delivery_instructions || '')
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const createSalesOrder = async ({shouldProceedAnyway = false} = {}) => {

        setIsLoading(true)
        const formData = {}

        formData['customer_id'] = customerId
        formData['ship_address_id'] = shipToAddressId
        formData['drop_trailer_no'] = dropTrailerNo
        formData['pickup_trailer_no'] = pickupTrailerNo
        formData['customer_po_no'] = customerPoNo
        formData['requested_delivery_date'] = dayjs(requestedDeliveryDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['requested_delivery_time'] = dayjs(requestedDeliveryTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
        formData['shipment_date'] = dayjs(shippedDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['site_id'] = choosesite
        formData['sales_order_items'] = salesOrderItems
        formData['so_status_id'] = 1
        formData['comments'] = comments
        formData['proceed_anyway'] = shouldProceedAnyway
        formData['delivery_ticket_no'] = deliveryTicketNo

        formData['type'] = includes(['SHIPMENT', 'CUSTOMER PICKUP', '3PL', 'BROKERED'], type) ? type : 'SHIPMENT'

        switch(type) {
            case "CUSTOMER PICKUP":
                formData['forklift_driver_id'] = forkliftDriverId
                formData['loaded_date'] = dayjs(loadedDate).format('YYYY-MM-DD HH:mm:ss')
                break
            case "3PL":
                formData['tpl_vendor_id'] = tplVendorId
                break
            case "BROKERED":
                formData['broker_id'] = brokerVendorId
                break
            default:
        }

        await axios.post(`/api/sales-orders`, formData, config).then(( data ) => {
            if(data.status === 202) {
                Swal.fire({
                    title: t('caution'),
                    text: data.data.accepted?.description ?? '',
                    icon: 'warning',
                    customClass: 'warning2',
                    showCloseButton: true,
                    iconColor: '#FEB806',
                    reverseButtons: true,
                    showCancelButton: true,

                    confirmButtonText: t('yes'),
                    cancelButtonText: t('negatory')
                }).then((result) => {
                    if(result.isConfirmed) {
                        createSalesOrder({
                            shouldProceedAnyway: true
                        })
                    }
                })
            } else {
                Swal.fire({
                    icon: "success",
                    text: data.data.success.message,
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E'
                })

                navigate("/sales-order/" + data.data.success.created.id, { state: { prevPathname: location.pathname } })

                handleToggleModal()
            }
        })
        .catch(({ response }) => {
            handleAxiosError({response: response})
        })
        .finally(() => {
            setIsLoading(false)
        })
    }

    const getColumns = () => {
        const columns = [];

        columns.push(
            {
                field: 'item_no',
                headerName: t('no'),
                flex: 0.7,
                minWidth: 75
            },
            {
                field: 'description',
                headerName: t('description'),
                flex: 1.5,
                maxWidth: 140
            },
            {
                field: 'unit_of_measure_code',
                headerName: t('uom'),
                flex: 0.7,
                minWidth: 75
            },
            {
                field: 'qty_ordered',
                headerName: t('qty_ordered'),
                flex: 1,
                minWidth: 100,
                align: 'center',
                headerAlign: 'center',
                renderCell: (params) => <QtyOrderedCell params={params} handler={handleUpdateItem} handlerQTR={handleUpdateItemQTY} />
            },
            {
                field: 'qty_to_ship',
                headerName: t('qty_to_ship'),
                flex: 1,
                minWidth: 100,
                align: 'center',
                headerAlign: 'center',
                renderCell: (params) => <QtyToShipCell params={params} t={t} handlerQTR={handleUpdateItemQTY} />
            },
            {
                field: 'qty_shipped',
                headerName: t('qty_shipped'),
                align: 'center',
                headerAlign: 'center',
                flex: 1,
                minWidth: 100,
                renderCell: (params) => <QtyShippedCell params={params} />
            }
        );

        if(canViewPrice(user, features)) {
            columns.push(
                {
                    field: 'unit_price',
                    headerName: t('unit_price'),
                    flex: 0.7,
                    minWidth: 75,
                    renderCell: (params) => <UnitPriceCell params={params} currencySymbol={currencySymbol} />
                },
                {
                    field: 'total_cost',
                    headerName: t('total'),
                    flex: 0.7,
                    minWidth: 75,
                    renderCell: (params) => <TotalCell params={params} currencySymbol={currencySymbol} />
                }
            );
        }

        columns.push(
            {
                field: 'actions',
                headerName: t('actions'),
                sortable: false,
                flex: 0.7,
                minWidth: 75,
                cellClassName: 'padding-0',
                renderCell: (params) => <Actions params={params} t={t} salesOrderItems={salesOrderItems} setSalesOrderItems={setSalesOrderItems} />
            }
        );

        return columns;
    }

    const TotalPrice = () => {
        if(canViewPrice(user, features)) {
            return (
                <div className='flex justify-between text-[#333333] text-[15px] pb-4'>
                    <p>{t('totalPrice')}</p>
                    <p className='font-[500]'>{currencySymbol}{totalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                </div>
            );
        }

        return;
    }

    const optionsCustomers = customers?.map(option => ({ label: option.customer_no + ' - ' + option.name, id: option.id }))
    const optionsTplVendors = tplVendors?.map(option => ({ label: option.vendor_no + ' - ' + option.name, id: option.id }))
    const optionsBrokerVendors = brokerVendors?.map(option => ({ label: option.vendor_no + ' - ' + option.name, id: option.id }))

    return (
        <>
            <Modal open={open} onClose={handleToggleModal}>
                <Box sx={style}>
                    <div className='flex justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                        <div className='flex items-center justify-between w-full leading-none'>
                            <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#336195] text-[35px] mr-4'>•</span> {t(headerTextKey)}</p>
                            <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                                <button onClick={handleToggleModal}><i className="fa-solid fa-plus"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className='p-5 pb-12 flex justify-between gap-3 flex-col lg:flex-row'>
                        <div className='bg-white p-6 pt-0 rounded-md w-full lg:w-2/5 h-fit edit-view'>
                            <div className='pb-11 pt-1'>
                                <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('general_information')}</p>
                            </div>
                            {/* Customer */}
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('customer')} *</p>
                                <div className='w-full'>
                                    <Autocomplete
                                        disablePortal
                                        disableClearable
                                        id="customers-autocomplete"
                                        options={optionsCustomers}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '0 !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        onChange={(a, b) => { setCustomerId(b?.id || ''); setDisabledButton(false) }}
                                        renderInput={(params) => <TextField {...params} autoFocus={true} />}
                                    />
                                </div>
                            </div>
                            {/* Ship to code */}
                            <div className='w-full pb-4'>
                                <Stack
                                    direction='row'
                                    gap={1}
                                    alignItems='baseline'
                                    justifyContent='space-between'
                                >
                                    <p className="text-[14px] text-[#718096]">{t('ship_to_location')} *</p>
                                    {(shipToAddressId && isValid(deliveryInstructions)) ? (
                                        <Tooltip
                                            title={
                                                <div style={{ whiteSpace: 'pre-line' }}>
                                                    {deliveryInstructions}
                                                </div>
                                            }
                                        >
                                            <Stack
                                                direction='row'
                                                gap={1}
                                                alignItems='center'
                                                className='text-[#718096]'
                                            >
                                                <MenuBookIcon fontSize='18px'/>
                                                <span className='text-[14px] min-[420px]:block hidden' style={{textTransform: 'uppercase'}}>{t('delivery_instructions')}</span>
                                            </Stack>
                                        </Tooltip>
                                    ) : null}
                                </Stack>

                                <div className='w-full'>
                                    <FormControl sx={{ width: '100%' }}>
                                        <Select
                                            value={shipToAddressId}
                                            onChange={(e) => { handleShipToLocation(e) }}
                                            sx={{
                                                width: '100%',
                                                background: '#FCFCFC',
                                                '.MuiOutlinedInput-input': {
                                                    padding: '9px !important'
                                                },
                                                boxShadow: 'none',
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    border: '1px solid #EEEFF2 !important'
                                                }
                                            }}
                                        >
                                            {shipToLocations?.map((item, index) => <MenuItem key={index} value={item.id}>{item.code} - {item.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {/* Address */}
                            {
                                shipToAddressId ?
                                    <div className='w-full p-4 address mb-4'>
                                        <div className='w-full'>{address}</div>
                                        {shipToAddressId ? <div className='w-full'>{zip} {city}, {state}</div> : <div className='w-full'></div>}
                                    </div>
                                    :
                                    null
                            }

                            {/* 3PL Vendor */}
                            {type === '3PL' ? (
                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('3pl_vendor')} *</p>
                                    <div className='w-full'>
                                        <Autocomplete
                                            disablePortal
                                            disableClearable
                                            id="tpl-vendors-autocomplete"
                                            options={optionsTplVendors}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            sx={{
                                                width: '100%',
                                                background: '#FCFCFC',
                                                '.MuiOutlinedInput-input': {
                                                    padding: '0 !important'
                                                },
                                                boxShadow: 'none',
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    border: '1px solid #EEEFF2 !important'
                                                }
                                            }}
                                            onChange={(a, b) => { setTplVendorId(b?.id || ''); setDisabledButton(false) }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </div>
                                </div>
                            ) : null}

                            {/* Broker */}
                            {type === 'BROKERED' ? (
                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('broker')} *</p>
                                    <div className='w-full'>
                                        <Autocomplete
                                            disablePortal
                                            disableClearable
                                            id="broker-vendors-autocomplete"
                                            options={optionsBrokerVendors}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            sx={{
                                                width: '100%',
                                                background: '#FCFCFC',
                                                '.MuiOutlinedInput-input': {
                                                    padding: '0 !important'
                                                },
                                                boxShadow: 'none',
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    border: '1px solid #EEEFF2 !important'
                                                }
                                            }}
                                            onChange={(a, b) => { setBrokerVendorId(b?.id || ''); setDisabledButton(false) }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </div>
                                </div>
                            ) : null}

                            {/* Drop Trailer No. */}
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('drop_trailer_no')}</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="drop_trailer_no" value={dropTrailerNo} onChange={(e) => { setDropTrailerNo(e.target.value) }} />
                                </div>
                            </div>

                            {/* Pickup Trailer No. */}
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('pickup_trailer_no')}</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                        type="text" className='w-full' name="pickup_trailer_no" value={pickupTrailerNo} onChange={(e) => { setPickupTrailerNo(e.target.value) }} />
                                </div>
                            </div>

                            {/* Customer PO No. */}
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('customer_po_no')}</p>
                                <div className='w-full'>
                                    <TextField
                                        sx={TEXTFIELD_STYLE}
                                        type="text"
                                        name="customer_po_no"
                                        value={customerPoNo}
                                        onChange={(e) => { handleCustomerPoNo(e) }}
                                    />
                                    <p className='text-xs italic roboto text-slate-500 pt-1'><i className="fa-solid fa-circle-info"></i> {t('the_maximum_character_limit_is_x', { x: 50 })}.</p>
                                </div>
                            </div>

                            {/* Delivery Ticket No. */}
                            {
                                includes(['oliverswoodworking'], localStorage.getItem('client_id')) ? (
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('delivery_ticket_no')}</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={{
                                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                }}
                                                type="text" className='w-full' name="delivery_ticket_no" value={deliveryTicketNo} onChange={(e) => { setDeliveryTicketNo(e.target.value) }} />
                                        </div>
                                    </div>
                                ) : null
                            }

                            <div className='lg:flex gap-3 w-full'>
                                {/* Requested Delivery Date */}
                                <div className='w-full lg:w-1/2 pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('requested_delivery_date')}</p>
                                    <div className='w-full'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                sx={{
                                                    width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                }}
                                                format="YYYY-MM-DD" value={requestedDeliveryDate} onChange={(newValue) => setRequestedDeliveryDate(newValue)} />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <div className='w-full lg:w-1/2 pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('requested_shipment_time')}</p>
                                    <div className='w-full'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer sx={{ padding: '0' }} components={['TimePicker', 'TimePicker']}>
                                                <MobileTimePicker
                                                    sx={{
                                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                                    }}
                                                    value={requestedDeliveryTime} onChange={(newValue) => setRequestedDeliveryTime(newValue)} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </div>

                            {/* Shippment Date */}
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('shipment_date')}</p>
                                <div className='w-full'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker
                                            sx={{
                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                            }}
                                            format="YYYY-MM-DD" value={shippedDate} onChange={(newValue) => setShippedDate(newValue)} />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>
                        <div className='w-full lg:w-3/5 h-fit'>
                            <div className='bg-white pt-0 rounded-md w-full edit-view mb-3'>
                                <div className='pt-1 pl-3'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('salesOrderitems')}</p>
                                </div>
                                <div className='flex justify-end p-5 pt-0 border-b'>
                                    <Tooltip disableInteractive title={t('addsalesOrderitems')} placement='bottom'>
                                        <div>
                                            <AddButton disabled={disabledButton} onClick={handleOpenSalesOrderItem}><i className="fa-solid fa-plus"></i></AddButton>
                                        </div>
                                    </Tooltip>
                                </div>
                                <div className='border-b'>
                                    {
                                        salesOrderItems.length > 0 ?
                                            <div className='flex justify-end w-full p-2'>
                                                <button className='px-2 py-1 text-white bg-red-600 font-bold rounded-md mb-1 roboto text-xs uppercase' onClick={e => setSalesOrderItems([])}>{t('clear_all')}</button>
                                            </div>
                                            :
                                            null
                                    }
                                    <TableShared columns={getColumns()} items={salesOrderItems} disableColumnMenu={true} />
                                </div>
                                <div className='flex justify-end w-full p-5'>
                                    <div className='w-full lg:w-1/3'>
                                        <TotalPrice />
                                        <div className='flex justify-between text-[#333333] text-[15px]'>
                                            <p>{t('totalQty')}</p>
                                            <p className='font-[500]'>{totalQty}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full'>
                                <div className='bg-white pt-0 rounded-md w-full edit-view mb-3'>
                                    <div className='pt-1 p-5'>
                                        <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('comments')}</p>
                                    </div>
                                    <div className='w-full p-5 pt-0'>
                                        <p className="text-[14px] text-[#718096]">{t('comments_text')}</p>
                                        <TextField
                                            multiline
                                            rows={3}
                                            value={comments}
                                            onChange={e => setComments(e.target.value)}
                                            sx={{
                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='w-full flex justify-end'>
                                <button type="button" onClick={createSalesOrder} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#DBA34B] hover:bg-[#CF7300] disabled:bg-[#B8B7BC]'>
                                    <span className='pr-2'><i className="fa-solid fa-plus"></i></span> {t('create')}
                                </button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
            <AddSalesOrderItem open={openSalesOrderItem} handleOpen={handleOpenSalesOrderItem} salesOrderItems={salesOrderItems} setSalesOrderItems={setSalesOrderItems} disabled={disabled} setDisabled={setDisabled} />
        </>
    )
}

export default CreateSalesOrder

const maxQtyDigits = 5

const QtyOrderedCell = ({ params, handler, handlerQTR }) => {
    const [qty, setQty] = useState(params.value)

    const handleZeroLocal = (e) => {
        if (e.target.value === "" || e.target.value === '-') {
            setQty(0)

            handler(
                params.row.id,
                0,
                params.row.unit_price
            )
        }
    }

    const handleQuantity = (e) => {
        const regex = createRegexFromString(`/^(-?\\d{0,${maxQtyDigits}})$/i`)
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty(0)
            } else {
                if (e.target.value < params.row.qty_to_ship) {
                    setQty(e.target.value)

                    handlerQTR(
                        params.row.id,
                        e.target.value,
                        params.row.qty_to_ship
                    )
                    handler(
                        params.row.id,
                        e.target.value,
                        params.row.unit_price
                    )
                } else {
                    setQty(e.target.value)

                    handler(
                        params.row.id,
                        e.target.value,
                        params.row.unit_price
                    )
                }
            }
        }
    }

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-ordered"
            value={qty}
            onChange={(e) => handleQuantity(e) }
            onBlur={handleZeroLocal}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
        />
    )
}

const QtyToShipCell = ({ params, t, handlerQTR }) => {
    const [qty, setQty] = useState(params.value)

    useEffect(() => {
        setQty(params.value)
    }, [params.row.qty_ordered])

    const handleQuantity = (e) => {
        const regex = createRegexFromString(`/^(-?\\d{0,${maxQtyDigits}})$/i`)
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty(0)
            } else {
                if (e.target.value > params.row.qty_ordered) {
                    setQty(params.row.qty_ordered)
                    handlerQTR(
                        params.row.id,
                        params.row.qty_ordered,
                        params.row.qty_ordered
                    )
                    Swal.fire({
                        text: t('warningSalesOrder'),
                        icon: 'warning',
                        customClass: 'error',
                        showCloseButton: true,
                        iconColor: '#FF0000',
                        reverseButtons: true,
                    })
                } else {
                    setQty(e.target.value)
                    handlerQTR(
                        params.row.id,
                        params.row.qty_ordered,
                        e.target.value,
                    )
                }
            }
        }
    }

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-to-ship"
            value={qty}
            onChange={(e) => handleQuantity(e) }
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
        />
    )
}

const QtyShippedCell = ({ params }) => {
    const [qty, setQty] = useState(params.value)

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-shipped"
            value={qty}
            onChange={(e) => handleInteger({
                event: e,
                setter: setQty,
                end: 30
            })}
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
            disabled
        />
    )
}

const UnitPriceCell = (params) => {
    return (
        <>{params.currencySymbol}{params.params.row.unit_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
}

const TotalCell = (params) => {
    const [total, setTotal] = useState(params.params.value)

    useEffect(() => {
        setTotal(params.params.row.qty_ordered * params.params.row.unit_price)
    }, [params])

    return (
        <>{params.currencySymbol}{total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
}

const Actions = ({ params, t, salesOrderItems, setSalesOrderItems }) => {
    const deleteItem = async (id) => {
        const filtered = salesOrderItems.filter(i => i.id != id)
        setSalesOrderItems(filtered)
    }

    return (
        <>
            <div className='flex justify-between'>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => deleteItem(params.row.id)}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}
