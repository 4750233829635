import React, { useState, useEffect, useRef } from 'react'

import { Link, useNavigate, useLocation } from 'react-router-dom'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import update from 'immutability-helper'
import { Tooltip } from '@mui/material'

import AppLayout from '../Layouts/AppLayout'
import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import '../../DispatchCalendar.css'
import Loading from '../Loading'
import { isValid } from '../../helpers/helper'
import i18next from 'i18next'
import allLocales from '@fullcalendar/core/locales-all'
import { includes } from 'lodash'
import { buildUrl } from '../../helpers/apiHelper'
import CombinedNavigation from './CombinedNavigation'
import { t } from 'i18next'
import { useAuth } from '../../hooks/auth'
import EventItems from '../common/calendar/EventItems'

const CombinedCalendar = () => {
    const { user } = useAuth({ middleware: 'guest ' })

    const navigate = useNavigate()
    const location = useLocation()
    const { config, choosesite, setChoosesite, pusher } = useStateContext()
    const [isLoading, setIsLoading] = useState(false)

    const calendarRef = useRef([])

    const [salesOrders, setSalesOrders] = useState([])
    const [purchaseOrders, setPurchaseOrders] = useState([])
    /* const [brokerTransactions, setBrokerTransactions] = useState([]) */

    const [shipmentsChecked, setShipmentsChecked] = useState(() => {
        if(localStorage.getItem('filters') !== null) {
            const filters = JSON.parse(localStorage.getItem('filters'))
            return filters[0]?.combined_calendar_shipments_checked ?? true
        } else {
            return true
        }
    })
    const [collectionsChecked, setCollectionsChecked] = useState(() => {
        if(localStorage.getItem('filters') !== null) {
            const filters = JSON.parse(localStorage.getItem('filters'))
            return filters[0]?.combined_calendar_collections_checked ?? true
        } else {
            return true
        }
    })
    /* const [transactionsChecked, setTransactionsChecked] = useState(() => {
        if(localStorage.getItem('filters') !== null) {
            const filters = JSON.parse(localStorage.getItem('filters'));
            return filters[0]?.combined_calendar_transactions_checked ?? true;
        } else {
            return true;
        }
    }) */

    const [initialView, setInitialView] = useState(() => {
        if(localStorage.getItem('filters') !== null) {
            const filters = JSON.parse(localStorage.getItem('filters'))
            if(filters[0]?.calendar_initial_view && includes(['dayGridMonth', 'timeGridWeek', 'timeGridDay'], filters[0]?.calendar_initial_view)) {
                return filters[0]?.calendar_initial_view
            } else {
                return 'dayGridMonth'
            }
        } else {
            return 'dayGridMonth'
        }
    })

    const [events, setEvents] = useState([])

    useEffect(() => {
        // Unsubscribe on unmount
        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-created-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-created-site-${localStorage.getItem('site')}`)
            /* pusher.unsubscribe(`${localStorage.getItem('client_id')}-brokertransaction-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-brokertransaction-updated-site-${localStorage.getItem('site')}`) */
        })
    }, [])

    useEffect(() => {
        if(isValid(choosesite)) {
            setEvents([])
            getSalesOrders(choosesite)
            getPurchaseOrders(choosesite)
            /* getBrokerTransactions(choosesite) */
        }
    }, [choosesite])

    useEffect(() => {
        if (isValid(choosesite)) {
            const channelSalesOrderDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${choosesite}`)
            const channelSalesOrderUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${choosesite}`)
            const channelSalesOrderCreated = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-created-site-${choosesite}`)
            const channelPurchaseOrderDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${choosesite}`)
            const channelPurchaseOrderUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${choosesite}`)
            const channelPurchaseOrderCreated = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-created-site-${choosesite}`)
            /* const channelBrokerTransactionDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-brokertransaction-deleted-site-${choosesite}`)
            const channelBrokerTransactionUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-brokertransaction-updated-site-${choosesite}`) */

            channelSalesOrderDelete.bind(`${localStorage.getItem('client_id')}-salesorder-deleted-event-site-${choosesite}`, data => {
                setSalesOrders((prev) => {
                    const item = prev.find((i) => i.id === data.id)
                    const exItem = prev.filter((i) => i.id !== item.id)
                    return exItem
                })
            })

            channelSalesOrderUpdate.bind(`${localStorage.getItem('client_id')}-salesorder-updated-event-site-${choosesite}`, data => {
                getSalesOrder(data.id, "updated");
            })

            channelSalesOrderCreated.bind(`${localStorage.getItem('client_id')}-salesorder-created-event-site-${choosesite}`, data => {
                getSalesOrder(data.id, "created");
            });

            channelPurchaseOrderDelete.bind(`${localStorage.getItem('client_id')}-purchaseorder-deleted-event-site-${choosesite}`, data => {
                setPurchaseOrders((prev) => {
                    const item = prev.find((i) => i.id === data.id)
                    const exItem = prev.filter((i) => i.id !== item.id)
                    return exItem
                })
            })

            channelPurchaseOrderUpdate.bind(`${localStorage.getItem('client_id')}-purchaseorder-updated-event-site-${choosesite}`, data => {
                getPurchaseOrder(data.id, "updated")
            })

            channelPurchaseOrderCreated.bind(`${localStorage.getItem('client_id')}-purchaseorder-created-event-site-${choosesite}`, data => {
                getPurchaseOrder(data.id, "created")
            })

            /* channelBrokerTransactionDelete.bind(`${localStorage.getItem('client_id')}-brokertransaction-deleted-event-site-${choosesite}`, data => {
                setBrokerTransactions((prev) => {
                    const item = prev.find((i) => i.id === data.id)
                    const exItem = prev.filter((i) => i.id !== item.id)
                    return exItem
                })
            })

            channelBrokerTransactionUpdate.bind(`${localStorage.getItem('client_id')}-brokertransaction-updated-event-site-${choosesite}`, data => {
                getPurchaseOrder(data.id)
            }) */
        }

        return (() => {
            if(isValid(choosesite)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-created-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-created-site-${choosesite}`)
                /* pusher.unsubscribe(`${localStorage.getItem('client_id')}-brokertransaction-deleted-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-brokertransaction-updated-site-${choosesite}`) */
            }
        })
    }, [choosesite])

    useEffect(() => {
        setEvents([])

        salesOrders.map((so) => {
            const newEvent =  {
                "order_id": so.id,
                "order_type": "sales",
                "title": so.so_number,
                "date": so.requested_delivery_date + 'T' + so.requested_delivery_time_time,
                "requested_delivery_date": so.requested_delivery_date,
                "customer_name": so.customer_name,
                "ship_address_name": so.ship_address_name,
                "items": so.sales_order_items
            }

            setEvents((prev) => [...prev, newEvent])
        })

        purchaseOrders.map((po) => {
            const newEvent = {
                "order_id": po.id,
                "order_type": "purchase",
                "title": po.po_number,
                "date": po.requested_collection_date + 'T' + po.requested_collection_time_time,
                "requested_collection_date": po.requested_collection_date,
                "vendor_name": po.vendor_name,
                "purchase_address_name": po.purchase_address_name,
                "items": po.purchase_order_items
            }

            setEvents((prev) => [...prev, newEvent])
        })

        /* brokerTransactions.map((bt) => {
            const newEvent = {
                "order_id": bt.id,
                "order_type": "transaction",
                "title": bt.sales_order?.so_number + ' ' + bt.purchase_order?.po_number,
                "date": bt.requested_delivery_date + 'T' + bt.requested_delivery_time_time,
                "requested_delivery_date": bt.requested_delivery_date,
            };

            setEvents((prev) => [...prev, newEvent]);
        }) */
    }, [salesOrders, purchaseOrders, /* brokerTransactions */])

    useEffect(() => {
        createFilterArray(shipmentsChecked, collectionsChecked, /* transactionsChecked */)
    }, [shipmentsChecked, collectionsChecked, /* transactionsChecked */])

    {/* Functions */}

    const createFilterArray = (shipmentsChecked, collectionsChecked, transactionsChecked = false) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].combined_calendar_shipments_checked = shipmentsChecked
        filters[0].combined_calendar_collections_checked = collectionsChecked
        /* filters[0].combined_calendar_transactions_checked = transactionsChecked */

        localStorage.setItem('filters', JSON.stringify(filters))
    }

    const getSalesOrders = async (site) => {
        setIsLoading(true)

        const url = buildUrl(
            '/api/list-orders',
            {
                where: {
                    'order_type': 'sales',
                    'site_id': site,
                    'pending': !localStorage.getItem('client_id').includes('bramptonpallet') ? true : ''
                }
            }
        )

        await axios
            .get(url, config)
            .then(res => {
                const data = res.data?.data.filter((i) => {
                    const upTo = !localStorage.getItem('client_id').includes('bramptonpallet') ? 9 : 10;
                    return i.so_status_id < upTo || i.so_status_id === 11;
                });
                setSalesOrders(data)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getPurchaseOrders = async (site) => {
        setIsLoading(true)

        const url = buildUrl(
            '/api/list-orders',
            {
                where: {
                    'order_type': 'purchase',
                    'site_id': site,
                    'pending': !localStorage.getItem('client_id').includes('bramptonpallet') ? true : ''
                }
            }
        )

        await axios
            .get(url, config)
            .then(res => {
                const data = res.data?.data.filter((i) => {
                    const upTo = !localStorage.getItem('client_id').includes('bramptonpallet') ? 10 : 11;
                    return i.po_status_id < upTo;
                });
                setPurchaseOrders(data)
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    /* const getBrokerTransactions = async (site) => {
        setIsLoading(true)

        const url = buildUrl(
            '/api/broker-transactions',
            {
                where: {
                    'site_id': site,
                }
            }
        )

        await axios.get(url, config)
            .then(res => {
                const data = res.data?.data.filter((i) => !includes(['invoiced'], i.bt_status));
                setBrokerTransactions(data)
            })
            .finally(() => {
                setIsLoading(false)
            })
    } */

    const getSalesOrder = async (id, state) => {
        setIsLoading(true)

        await axios.get(`/api/sales-orders/${id}`, config)
            .then(res => {
                const salesOrder = res.data
                if (state === 'created')
                    setSalesOrders((prev) => [...prev, salesOrder]);

                if (state === 'updated') {
                    setSalesOrders((prev) => {

                        const index = prev?.findIndex((i) => i.id === id)
                        if (index < 0) {
                            const items = [...prev, salesOrder]
                            return items
                        } else {
                            const finalStatus = localStorage.getItem('client_id').includes('bramptonpallet') ? 'invoiced' : 'shipped';

                            if (salesOrder.so_status === finalStatus && salesOrder.so_status !== 'completed') {
                                return update(prev, { $splice: [[index, 1]] })
                            } else {
                                return update(prev,
                                    { [index]: { $set: salesOrder } }
                                )
                            }
                        }
                    });
                }
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getPurchaseOrder = async (id, state) => {
        setIsLoading(true)

        await axios.get(`/api/purchase-orders/${id}`, config)
            .then(res => {
                const purchaseOrder = res.data
                if (state === 'created')
                    setPurchaseOrders((prev) => [...prev, purchaseOrder]);

                if (state === 'updated') {
                    setPurchaseOrders((prev) => {
                        const index = prev?.findIndex((i) => i.id === id)
                        if (index < 0) {
                            const items = [...prev, purchaseOrder]
                            return items
                        } else {
                            if (includes(["invoiced", "rejected"], purchaseOrder.po_status)) {
                                return update(prev, { $splice: [[index, 1]] })
                            } else {
                                return update(prev,
                                    { [index]: { $set: purchaseOrder } }
                                )
                            }
                        }
                    })
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleEventClick = (eventInfo) => {
        const orderType = eventInfo?.event.extendedProps.order_type

        if(orderType === 'sales') {
            localStorage.setItem('initialDateSales', eventInfo.event.extendedProps.requested_delivery_date)
            navigate(`/sales-order/${eventInfo.event.extendedProps.order_id}`, { state: { route: '/dispatch-calendar', prevPathname: location.pathname } });
        } else if (orderType === 'purchase') {
            localStorage.setItem('initialDatePurchase', eventInfo.event.extendedProps.requested_collection_date)
            navigate(`/purchase-order/${eventInfo.event.extendedProps.order_id}`, { state: { route: '/dispatch-calendar', prevPathname: location.pathname } });
        } /* else if (orderType === 'transaction') {
            localStorage.setItem('initialDatePurchase', eventInfo.event.extendedProps.requested_collection_date)
            navigate(`/broker-transaction/${eventInfo.event.extendedProps.order_id}`, { state: { route: '/dispatch-calendar', prevPathname: location.pathname } });
        } */
    }

    return (
        <>
            {isLoading ? <Loading /> : null}
            <AppLayout>
                <CombinedNavigation
                    user={user}
                    shipmentsChecked={shipmentsChecked}
                    collectionsChecked={collectionsChecked}
                    /* transactionsChecked={transactionsChecked} */
                    setShipmentsChecked={setShipmentsChecked}
                    setCollectionsChecked={setCollectionsChecked}
                    /* setTransactionsChecked={setTransactionsChecked} */
                />
                <div className='p-5 w-full'>
                    <div className='p-2 bg-white mb-2 rounded-md dispatch'>
                        <FullCalendar
                            locales={allLocales}
                            locale={i18next?.resolvedLanguage ?? 'en'}
                            plugins={[dayGridPlugin, timeGridPlugin]}
                            ref={calendarRef}
                            headerToolbar={{
                                left: 'prev,next today',
                                center: 'title',
                                right: 'dayGridMonth,timeGridWeek,timeGridDay'
                            }}
                            buttonText={{
                                today: t('today'),
                                month: t('month'),
                                week: t('week'),
                                day: t('day')
                            }}
                            customButtons={{
                                today: {
                                    text: t('today'),
                                    click: () => {
                                        localStorage.removeItem('initialDatePurchase')
                                        let calendarApi = calendarRef.current.getApi()
                                        calendarApi.today()
                                    }
                                }
                            }}
                            initialView={initialView}
                            datesSet={(args) => {
                                if (localStorage.getItem('filters') === null) {
                                    let filter = [{}]
                                    localStorage.setItem('filters', JSON.stringify(filter))
                                }

                                let filters = JSON.parse(localStorage.getItem('filters'))

                                filters[0].calendar_initial_view = args.view.type

                                localStorage.setItem('filters', JSON.stringify(filters))
                            }}
                            events={events?.filter((e) => {
                                const eligibleOrderTypes = [];

                                if(shipmentsChecked)
                                    eligibleOrderTypes.push('sales');

                                if(collectionsChecked)
                                    eligibleOrderTypes.push('purchase');

                                /* if(transactionsChecked)
                                    eligibleOrderTypes.push('transaction'); */

                                return includes(eligibleOrderTypes, e.order_type);
                            })}
                            eventContent={renderEventContent}
                            dayMaxEvents={true}
                            eventMaxStack={1}
                            expandRows={true}
                            eventColor='#ffffff'
                            initialDate={localStorage.getItem('initialDatePurchase')}
                            eventClick={handleEventClick}
                        />
                    </div>
                </div>
            </AppLayout>
        </>
    )
}

export default CombinedCalendar

function renderEventContent(eventInfo) {
    const { items, order_type, order_id } = eventInfo.event.extendedProps;

    const getButtonColor = (orderType) => {
        let className = '';

        switch(orderType) {
            case "sales":
                className = "bg-[#336195]";
                break;
            case "purchase":
                className = "bg-[#b7472a]";
                break;
            default:
                className = "bg-[#148c8cb3]";
        }

        return className;
    }

    return (
        <Tooltip disableInteractive placement='left' title={
            <div className='flex flex-col'>
                <p className='text-[16px] pb-3'>{eventInfo.event.title}</p>
                {eventInfo.event.extendedProps.order_type === 'sales' ? (
                    <>
                        <i className='text-[14px]'>{eventInfo.event.extendedProps.customer_name}</i>
                        <i className='text-[14px]'>{eventInfo.event.extendedProps.ship_address_name}</i>
                        <EventItems items={items} orderId={order_id} orderType={order_type} />
                    </>
                ) : eventInfo.event.extendedProps.order_type === 'purchase' ? (
                    <>
                        <i className='text-[14px]'>{eventInfo.event.extendedProps.vendor_name}</i>
                        <i className='text-[14px]'>{eventInfo.event.extendedProps.purchase_address_name}</i>
                        <EventItems items={items} orderId={order_id} orderType={order_type} />
                    </>
                ) : (
                    <>
                        <i className='text-[14px]'>{eventInfo.event.title}</i>
                    </>
                )}
            </div>
        }>
            <button className='w-full'>
                <div className={`p-1 flex flex-col text-left ${getButtonColor(eventInfo.event.extendedProps.order_type)} text-white rounded-sm`}>
                    <p className='text-xl bold p-1 roboto'>{eventInfo.event.title}</p>
                    {eventInfo.event.extendedProps.order_type === 'sales' ? (
                        <>
                            <i>{eventInfo.event.extendedProps.customer_name}</i>
                            <i>{eventInfo.event.extendedProps.ship_address_name}</i>
                            <EventItems items={items} orderId={order_id} orderType={order_type} />
                        </>
                    ) : eventInfo.event.extendedProps.order_type === 'purchase' ? (
                        <>
                            <i>{eventInfo.event.extendedProps.vendor_name}</i>
                            <i>{eventInfo.event.extendedProps.purchase_address_name}</i>
                            <EventItems items={items} orderId={order_id} orderType={order_type} />
                        </>
                    ) : (
                        <>
                            <i>{eventInfo.event.title}</i>
                        </>
                    )}
                </div>
            </button>
        </Tooltip>
    )
}
