const EventItems = ({
    items,
    orderId,
    orderType,
    showItems = true
}) => {
    return showItems && (
        <ul>
            {items?.map((item, index) => (
                <li key={`item-${index}-order-${orderId}-type-${orderType}`}>- {item?.description}</li>
            ))}
        </ul>
    )
}

export default EventItems
