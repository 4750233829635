import { sortBy, isNil, includes, has, toNumber } from 'lodash'
import Swal from 'sweetalert2'
import i18next from 'i18next'

// Not advised to remove. Integral for the proper functioning of the Reports view.
export const sortByProperty = (data, property) => {
    return ((data.length > 0 && data.every(d => d.hasOwnProperty(property))) ? sortBy(data, ['d', property]) : data)
}

/**
 * Made primarily to aid in the handling of (multi)Select Chip removal.
 *
 * Accepts:
 * - an event
 * - an ID of an object - i.e. an integer
 * - an object array of SELECTED options
 * - the setter for said array
 * - a setter for a state that controls the focus of a Select
 *
 * The focus setter (last param) is necessary to ensure the desired behaviour of the (multi)Select components.
 * */
export const handleDeleteSelectionObject = (e, id, selectedOptions, setSelectedOptions, setOpenOptions) => {
    const shouldDelete = selectedOptions.find((x) => x.id === id)
    if(shouldDelete) {
        const filtered = selectedOptions.filter((x) => x.id !== shouldDelete.id)
        if(filtered.length > 0) {
            setSelectedOptions(filtered)
        } else {
            setSelectedOptions([])
            setOpenOptions(false)
        }
    }
}

/**
 * An augmentations of the handleDeleteSelectionObject. Accepts nearly everything that one does with one addition: a setter for an array of object IDs.
 *
 * Accepts:
 * - an event
 * - an ID of an object - i.e. an integer
 * - an object array of SELECTED options
 * - the setter for said array
 * - the setter for an array of object IDs (***NEW***)
 * - a setter for a state that controls the focus of a Select
 *
 * The focus setter (last param) is necessary to ensure the desired behaviour of the (multi)Select components.
 * */
export const handleDeleteSelectionObjectPlus = (e, id, selectedOptions, setSelectedOptions, setSelectedOptionIds, setOpenOptions) => {
    const shouldDelete = selectedOptions.find((x) => x.id === id)
    const ids = []
    if(shouldDelete) {
        const filtered = selectedOptions.filter((x) => x.id !== shouldDelete.id)
        if(filtered.length > 0) {
            filtered.forEach((o) => {
                if(o.hasOwnProperty('id')) ids.push(o.id)
            })
            setSelectedOptions(filtered)
            setSelectedOptionIds(ids)
        } else {
            setSelectedOptions([])
            setSelectedOptionIds([])
            setOpenOptions(false)
        }
    }
}

/**
 * Made primarily to aid in the handling of (multi)Select Chip removal.
 *
 * Accepts:
 * - an event
 * - a value - i.e., a primitive of some sort (integer, string, etc.)
 * - a primitive array of SELECTED options
 * - the setter for said array
 * - a setter for a state that controls the focus of a Select
 *
 * The focus setter (last param) is necessary to ensure the desired behaviour of the (multi)Select components.
 * */
export const handleDeleteSelectionPrimitive = (e, val, selectedOptions, setSelectedOptions, setOpenOptions) => {
    const shouldDelete = selectedOptions.find((x) => x === val)
    if(shouldDelete) {
        const filtered = selectedOptions.filter((x) => x !== shouldDelete)
        if(filtered.length > 0) {
            setSelectedOptions(filtered)
        } else {
            setSelectedOptions([])
            setOpenOptions(false)
        }
    }
}

/**
 * Used primarily to check if choosesite or company_id context variables are valid.
 * It checks that the value is:
 * - not of _null_ or _undefined_ types
 * - not equal to 'null', 'undefined', or '' (strings)
 *
 * @param {*} value
 * @returns boolean
 */
export const isValid = (value) => {
    if(
        value
        && !isNil(value)
        && !includes(['undefined', 'null', ''], value)
    ) {
        return true
    } else {
        return false
    }
}

export function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
}

/**
 * Used to extract error messages and descriptions from backend responses
 *
 * Added because ocassionally backend resposnse will be structured unconventionally and thus error messages
 * may not be displayed on the frontend. This is because - for example - the frontend expects to find the message by following a certain path (response.data.error.message),
 * but the message winds up being in a different place instead (response.data.message).
 *
 * @param {*} response
 * @returns string
 */
export const extractErrorMessage = (response) => {
    if(has(response, 'data.error.message')) {
        return response.data.error.message
    } else if(has(response, 'data.message')) {
        return response.data.message
    }

    return i18next.t('something_went_wrong');
}

export const extractErrorDescription = (response) => {
    if(has(response, 'data.error.description')) {
        return response.data.error.description
    } else if(has(response, 'data.description')) {
        return response.data.description
    }

    return i18next.t('contact_support_message');
}

/**
 * Used to display warning on caught Axios exceptions.
 *
 * Added in attempt to simplify and shorten code as these blocks bear repeating.
 *
 * @param {*} response
 * @param bool showMessage
 * @param bool showDescription
 */
export const handleAxiosError = ({ response, showDescription = false }) => {
    if(response?.status === 500) {
        Swal.fire({
            title: extractErrorMessage(response),
            text: extractErrorDescription(response),
            icon: "error",
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000'
        })
    } else {
        if(showDescription) {
            Swal.fire({
                title: extractErrorMessage(response),
                text: extractErrorDescription(response),
                icon: "error",
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000'
            })
        } else {
            Swal.fire({
                text: extractErrorMessage(response),
                icon: "error",
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000'
            })
        }

    }
}

export const areAllValid = (...array) => {
    array.forEach(value => {
        if(!isValid(value)) {
            return false
        }
    });

    return true
}

/**
 * Accepts and validates an email, testing it against a pattern which indicates that the provided email meets the RFC 2822 email standard if it passes.
 *
 * Pattern created by _Tripleaxis_  on regexr.com
 * Source: https://regexr.com/2rhq7
 * */
export const isValidRFC2822 = (email) => {
    return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(String(email).toLowerCase())
}

{/* NUMBER HANDLERS */}

/**
 * Creates and returns a regular expression object from a string.
 *
 * NOTE: Remember to escape backslashes (\ to \\).
 *
 * Credit to IonicaBizau (https://github.com/IonicaBizau). See https://github.com/IonicaBizau/regex-parser.js
 *
 * @param {String} string The string that should be converted to a RegExp object.
 * @returns {RegExp} The RegExp object created out of the inputted string
 */
export const createRegexFromString = (string) => {
    // Validate string type
    if(typeof string !== 'string') {
        throw new Error("Invalid input. Input must be a string");
    }

    const validFormat = string.match(/(\/?)(.+)\1([a-z]*)/i)

    // Check regex format validity
    if(!validFormat) {
        throw new Error("Invalid regular expression format.");
    }

    // Filter valid regex flags. See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_expressions#advanced_searching_with_flags
    const validFlags = Array.from(new Set([validFormat[3]]))
        .filter((flag) => 'gimsuy'.includes(flag))
        .join("")

    return new RegExp(validFormat[2], validFlags)
}

export const deleteZero = ({
    event,
    setter = () => {},
    value
}) => {
    if (toNumber(value) === 0) {
        setter('')
    }
}

export const handleZero = ({
    event,
    setter = () => {}
}) => {
    if (event.target.value === "" || event.target.value === '-') {
        setter(0)
    }
}

export const handleNumber = ({
    event,
    setter = () => {},
    allowNegatives = false,
    start = 0,
    end = 5,
    precision = 30
}) => {
    const regex = createRegexFromString(`/^(-?\\d{${start},${end}})((\\.(\\d{0,${precision}})?)?)$/i`)
    if (regex.test(event.target.value)) {
        if (
            (!allowNegatives && event.target.value < 0)
            || (!allowNegatives && event.target.value === '-')
            || (event.target.value === '.')
        ) {
            setter(0)
        }
        else {
            setter(event.target.value)
        }
    }
}

export const handleInteger = ({
    event,
    setter = () => {},
    allowNegatives = false,
    start = 0,
    end = 5,
    upperLimit = Number.MAX_SAFE_INTEGER,
    lowerLimit = Number.MIN_SAFE_INTEGER,
    name = i18next.t('number')
}) => {
    const regex = createRegexFromString(`/^(-?\\d{${start},${end}})$/i`)
    if (regex.test(event.target.value)) {
        if(event.target.value < lowerLimit) {
            Swal.fire({
                text: i18next.t('x_cannot_be_lesser_than_y', {
                    x: name,
                    y: lowerLimit
                }),
                icon: "error",
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000'
            })
            setter(lowerLimit)
            return
        }

        if(event.target.value > upperLimit) {
            Swal.fire({
                text: i18next.t('x_cannot_be_greater_than_y', {
                    x: name,
                    y: upperLimit
                }),
                icon: "error",
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000'
            })
            setter(upperLimit)
            return
        }

        if (
            (!allowNegatives && event.target.value < 0)
            || (!allowNegatives && event.target.value === '-')
        ) {
            setter(0)
            return
        }

        setter(event.target.value)
        return
    }
}

export const hasPermissions = (permissions, targets = []) => {
    return permissions.some((permission) => targets.includes(permission.name))
}

export const getTableSharedSx = ({
    breakHeaders,
    hideSortingButton,
    oddColumnPadding,
    headerRowFontSize,
    flat
}) => {
    return {
        '& .MuiTablePagination-toolbar': {
            color: '#88909C !important', fontWeight: '400'
        },
        '& .MuiDataGrid-columnHeaderTitle': breakHeaders ? {
            lineHeight: '21px',
            wordBreak: 'break-word',
            whiteSpace: 'pre-line',
            color: '#88909C !important', fontWeight: '400'
        } : {color: '#88909C !important', fontWeight: '400'},
        '& .MuiButtonBase-root': hideSortingButton ? {
            display: 'none'
        } : {},
        '& .MuiDataGrid-cell': {
            paddingLeft: `${oddColumnPadding}`,
            paddingRight: `${oddColumnPadding}`,
            borderRadius: '0px !important'
        },
        '& .MuiDataGrid-columnHeader': {
            paddingLeft: `${oddColumnPadding}`,
            paddingRight: `${oddColumnPadding}`,
            fontSize: `${headerRowFontSize}`
        },
        '&, [class^=MuiDataGrid]': {
            borderRadius: flat ? '0px' : '4px'
        },
        border: 'none',
        background: 'white'

    };
}

export const canViewPrice = (user, features) => {
    return (
        features?.ONLY_ADMINS_CAN_VIEW_PRICES != 1
        || (
            features?.ONLY_ADMINS_CAN_VIEW_PRICES == 1
            && ['master_admin'].includes(user?.role)
        )
    )
}
