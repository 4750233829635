import './App.css'
import 'tailwindcss/tailwind.css'
import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Login from './pages/auth/Login'
import ForgotPassword from './pages/auth/ForgotPassword'
import ChooseSite from './pages/Choosesite'
import ChoosePortal from './pages/ChoosePortal'
import Users from './pages/admin/Users'
import Import from './pages/admin/Import'
import Companies from './pages/admin/Companies'
import Sites from './pages/admin/Sites'
import Error from './pages/404'
import RequireAuth from './components/RequireAuth'
import RequireSite from './components/RequireSite'
import Customers from './pages/admin/Customers'
import Vendors from './pages/admin/Vendors'
import Items from './pages/admin/Items'
import GradeCodes from './pages/admin/GradeCodes'
import UnitsOfMeasure from './pages/admin/UnitsOfMeasure'
import SalesOrder from './pages/admin/SalesOrder'
import PurchaseOrder from './pages/admin/PurchaseOrder'
import Shipments from './components/sales_purchase_orders/Shipments'
import Collections from './components/sales_purchase_orders/Collections'
import EditSalesOrder from './components/sales_purchase_orders/EditSalesOrder'
import EditPurchaseOrder from './components/sales_purchase_orders/EditPurchaseOrder'
import UpdateUnitOfMeasure from './pages/UpdatePages/UpdateUnitOfMeasure'
import UpdateGradeCodes from './pages/UpdatePages/UpdateGradeCodes'
import Dispach from './pages/admin/Dispach'
import AvailableLoads from './pages/AvailableLoads'
import ClientDashboard from './pages/client/ClientDashboard'
import ProceedShipment from './components/driver/ProceedShipment'
import ProceedCollection from './components/driver/ProceedCollection'
import { useAuth } from './hooks/auth'
import DigitalGradeSheet from './pages/forklift_driver/DigitalGradeSheet'
import UpdateItems from './pages/UpdatePages/UpdateItems'
import ProductionTypes from './pages/Production/ProductionTypes'
import UpdateProductionType from './pages/Production/Update/UpdateProductionType'
import ProductionEmployees from './pages/Production/ProductionEmployees'
import UpdateProductionEmployee from './pages/Production/Update/UpdateProductionEmployee'
import CreateProductionEmployee from './pages/Production/Create/CreateProductionEmployee'
import Dashboard from './pages/Dashboard'
import ProductionItems from './pages/Production/ProductionItems'
import EnterProductionData from './pages/Production/EnterProductionData'
import Contacts from './pages/customer/Contacts'
import UpdateContacts from './pages/customer/UpdateContacts'
import UpdateInsightsUser from './components/customer_portal/UpdateInsightsUser'
import CustomerDashboard from './pages/customer/CustomerDashboard'
import CollectionDashboard from './pages/customer/CollectionDashboard'
import MyRequests from './pages/customer/MyRequests'
import ProceededShipments from './pages/customer/ProceededShipments'
import ProceededCollections from './pages/customer/ProceededCollections'
import Reports from './pages/admin/Reports'
import UpdateCustomer from './pages/UpdatePages/UpdateCustomer'
import CustomerRequests from './pages/customer/CustomerRequests'
import Calendar from './components/sales_purchase_orders/Calendar'
import CalendarBroker from './components/broker/Calendar'
import Map from './pages/Map'
import CollectionCalendar from './components/sales_purchase_orders/CollectionCalendar'
import UpdateShipToAddress from './pages/UpdatePages/UpdateShipToAddress'
import UpdateVendor from './pages/UpdatePages/UpdateVendor'
import EnterCounts from './pages/inventory/EnterCounts'
import UpdateCount from './pages/inventory/UpdateCount'
import CountsList from './pages/inventory/CountsList'
import LoginExternal from './pages/external/Login'
import AvailableLoadsExternal from './pages/external/AvailableLoads'
import UpdateUser from './pages/UpdatePages/UpdateUser'
import ChangePassword from './pages/auth/ChangePassword'
import RequireLogout from './components/RequireLogout'
import Logout from './components/Logout'
import BrokerTransactions from './pages/broker/BrokerTransactions'
import BrokerTransactionsDailyBoard from './pages/broker/BrokerTransactionsDailyBoard'
import UpdateBrokerTransaction from './components/broker/UpdateBrokerTransaction'
import UpdatePurchaseAddress from './pages/UpdatePages/UpdatePurchaseAddress'
import UpdatePurchaseContracts from './pages/UpdatePages/UpdatePurchaseContracts'
import UpdateSalesContracts from './pages/UpdatePages/UpdateSalesContracts'
import AdminDashboard from './pages/admin/AdminDashboard'
import CalenderDispatch from './components/dispatch/CalenderDispatch'
import ResetPassword from './pages/auth/ResetPassword'
import ProductionOrderBoard from './pages/Production/production_orders/Admin/ProductionOrderBoard'
import ProductionOrderBoardCalendar from './pages/Production/production_orders/Admin/ProductionOrderBoardCalendar'
import ProductionOrders from './pages/Production/production_orders/Admin/ProductionOrders'
import PO4Supervisor from './pages/Production/production_orders/Supervisor/ProductionOrders'
import UpdateProductionOrder from './pages/Production/Update/UpdateProductionOrder'
import ProcessProductionOrder from './pages/Production/production_orders/Supervisor/ProcessProductionOrder'
import SalesOrderDailyBoard from './pages/service_provider/SalesOrderDailyBoard'
import PurchaseOrderDailyBoard from './pages/service_provider/PurchaseOrderDailyBoard'
import SalesOrderDailyBoardCalendar from './pages/service_provider/calendars/SalesOrderDailyBoardCalendar'
import PurchaseOrderDailyBoardCalendar from './pages/service_provider/calendars/PurchaseOrderDailyBoardCalendar'
import UpdateBrokerSalesOrder from './pages/service_provider/update/UpdateBrokerSalesOrder'
import UpdateBrokerPurchaseOrder from './pages/service_provider/update/UpdateBrokerPurchaseOrder'
import UpdateVendorUser from './components/customer_portal/UpdateVendorUser'
import ServiceProviders from './pages/service_provider/ServiceProviders'
import UpdateServiceProvider from './pages/service_provider/update/UpdateServiceProvider'
import UpdateServiceProviderUser from './pages/service_provider/update/UpdateServiceProviderUser'
import CombinedCalendar from './components/sales_purchase_orders/CombinedCalendar'

function App() {
    const { user } = useAuth({ middleware: 'guest ' })

    // This will only encompass the initial non-Impersonable login.
    const role = user?.role ?? 'guest'
    const permissions = user?.permissions ?? []

    const getDefaultRoute = () => {
        switch (role) {
            case 'master_admin':
            case 'office_manager':
                return 'admin-dashboard'
            case 'salesperson':
                return 'sales-order'
            case 'driver':
            case 'forklift_driver':
                return 'available-loads'
            case 'production_supervisor':
                return 'production-employees'
            case 'customers_user':
                return 'shipments-dashboard'
            case 'dispatcher':
                return 'dispatch'
            case 'vendor_user':
            case 'service_provider_user':
                return 'sales-orders'
            default:
                return 'dashboard'
        }
    }

    const userHasPermissions = (targets = []) => {
        return permissions?.some((permission) => targets.includes(permission.name))
    }

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/password-reset/:token" element={<ResetPassword />} />
                    <Route element={<RequireAuth
                        allowedRoles={['master_admin', 'office_manager', 'production_supervisor', 'driver', 'forklift_driver', 'dispatcher', 'salesperson', 'client', 'customers_user', 'vendor_user', 'service_provider_user']} />}>
                        <Route path="/choosesite" element={<ChooseSite />} />
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['customers_user', 'vendor_user', 'service_provider_user']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="/choose-portal" element={<ChoosePortal />} />
                        </Route>
                    </Route>
                    <Route element={<RequireAuth
                        allowedRoles={['master_admin', 'office_manager', 'production_supervisor', 'driver', 'forklift_driver', 'dispatcher', 'salesperson', 'client', 'customers_user', 'vendor_user', 'service_provider_user']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="/" element={<Navigate replace={true} to={getDefaultRoute()} />} />
                        </Route>
                    </Route>

                    {/* Admin Dashboard */}
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="admin-dashboard" element={<AdminDashboard />} />
                        </Route>
                    </Route>

                    {/* users routes */}
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="users" element={<Users />} />
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="users/:id" element={<UpdateUser />} />
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="users/change-password/:id" element={<ChangePassword />} />
                        </Route>
                    </Route>


                    {/* pages */}
                    <Route element={<RequireAuth allowedRoles={['master_admin']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="import" element={<Import />} />
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="sites" element={<Sites />} />
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="companies" element={<Companies />} />
                        </Route>
                    </Route>
                    {/* All related to customers */}
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="customers" element={<Customers />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="customers/:id" element={<UpdateCustomer />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="ship-to-address/:id" element={<UpdateShipToAddress />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="sales-contracts/:id" element={<UpdateSalesContracts />}></Route>
                        </Route>
                    </Route>


                    {/* All related to vendors */}
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="vendors" element={<Vendors />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="vendors/:id" element={<UpdateVendor />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="purchase-address/:id" element={<UpdatePurchaseAddress />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="purchase-contracts/:id" element={<UpdatePurchaseContracts />}></Route>
                        </Route>
                    </Route>

                    {/* All related to Service Providers */}
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />}>
                            <Route path="service-providers" element={<ServiceProviders />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />}>
                            <Route path="service-providers/:id" element={<UpdateServiceProvider />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />}>
                            <Route path="service-provider-user/:id" element={<UpdateServiceProviderUser />}></Route>
                        </Route>
                    </Route>

                    {/* All related to items */}
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="items" element={<Items />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="items/:id" element={<UpdateItems />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="grade-codes" element={<GradeCodes />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="grade-codes/:id" element={<UpdateGradeCodes />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="units-of-measure" element={<UnitsOfMeasure />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="units-of-measure/:id" element={<UpdateUnitOfMeasure />}></Route>
                        </Route>
                    </Route>

                    {/* Sales orders */}
                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'master_admin',
                                        'office_manager',
                                        'salesperson',
                                        'dispatcher',
                                        userHasPermissions(['daily-board-view', 'daily-board-update']) && role
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="sales-order" element={<SalesOrder />}></Route>
                        </Route>
                    </Route>

                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'master_admin',
                                        'office_manager',
                                        'salesperson',
                                        'dispatcher',
                                        userHasPermissions(['daily-board-view', 'daily-board-update']) && role
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="combined-calendar" element={<CombinedCalendar />}></Route>
                        </Route>
                    </Route>

                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'master_admin',
                                        'office_manager',
                                        'salesperson',
                                        'dispatcher',
                                        userHasPermissions(['daily-board-view', 'daily-board-update']) && role
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="sales-order-calendar" element={<Calendar />}></Route>
                        </Route>
                    </Route>

                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'master_admin',
                                        'office_manager',
                                        'customers_user',
                                        'salesperson',
                                        'dispatcher',
                                        userHasPermissions(['daily-board-view', 'daily-board-update', 'dispatch']) && role
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="sales-order/:id" element={<EditSalesOrder />}></Route>
                        </Route>
                    </Route>

                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager', 'vendor_user', 'service_provider_user']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="shipments" element={<Shipments />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['driver', 'tpl_driver', 'service_provider']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="shipments/:id" element={<ProceedShipment />}></Route>
                        </Route>
                    </Route>

                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'vendor_user',
                                        'service_provider_user'
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="sales-orders" element={<SalesOrderDailyBoard />}></Route>
                        </Route>
                    </Route>

                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'vendor_user',
                                        'service_provider_user'
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="sales-orders-calendar" element={<SalesOrderDailyBoardCalendar />}></Route>
                        </Route>
                    </Route>

                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'vendor_user',
                                        'service_provider_user'
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="sales-orders/:id" element={<UpdateBrokerSalesOrder />}></Route>
                        </Route>
                    </Route>

                    {/* Purchase orders */}
                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'master_admin',
                                        'office_manager',
                                        'salesperson',
                                        'dispatcher',
                                        userHasPermissions(['daily-board-view', 'daily-board-update']) && role
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="purchase-order" element={<PurchaseOrder />}></Route>
                        </Route>
                    </Route>
                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'master_admin',
                                        'office_manager',
                                        'salesperson',
                                        'dispatcher',
                                        userHasPermissions(['daily-board-view', 'daily-board-update']) && role
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="purchase-order-calendar" element={<CollectionCalendar />}></Route>
                        </Route>
                    </Route>
                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'master_admin',
                                        'office_manager',
                                        'customers_user',
                                        'salesperson',
                                        'dispatcher',
                                        userHasPermissions(['daily-board-view', 'daily-board-update', 'dispatch']) && role
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="purchase-order/:id" element={<EditPurchaseOrder />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager', 'vendor_user', 'service_provider_user']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="collections" element={<Collections />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['driver', 'tpl_driver', 'service_provider']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="collections/:id" element={<ProceedCollection />}></Route>
                        </Route>
                    </Route>
                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'master_admin',
                                        'office_manager',
                                        'forklift_driver',
                                        userHasPermissions(['daily-board-update']) && role
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="collection/grade-sheet/:id" element={<DigitalGradeSheet />}></Route>
                        </Route>
                    </Route>

                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'vendor_user',
                                        'service_provider_user'
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="purchase-orders" element={<PurchaseOrderDailyBoard />}></Route>
                        </Route>
                    </Route>

                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'vendor_user',
                                        'service_provider_user'
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="purchase-orders-calendar" element={<PurchaseOrderDailyBoardCalendar />}></Route>
                        </Route>
                    </Route>

                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'vendor_user',
                                        'service_provider_user'
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="purchase-orders/:id" element={<UpdateBrokerPurchaseOrder />}></Route>
                        </Route>
                    </Route>

                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />}>
                            <Route path="vendor-user/:id" element={<UpdateVendorUser />}></Route>
                        </Route>
                    </Route>

                    {/* Production orders */}
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager', 'production_supervisor']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="production-order" element={<PO4Supervisor />}></Route>
                        </Route>
                    </Route>

                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'master_admin',
                                        'office_manager',
                                        userHasPermissions(['production-orders-view', 'production-orders-update']) && role
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="production-order-board" element={<ProductionOrderBoard />}></Route>
                        </Route>
                    </Route>
                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'master_admin',
                                        'office_manager',
                                        userHasPermissions(['production-orders-view', 'production-orders-update']) && role
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="production-order-board-calendar" element={<ProductionOrderBoardCalendar />}></Route>
                        </Route>
                    </Route>
                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'master_admin',
                                        'office_manager',
                                        userHasPermissions(['production-order-history']) && role
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="production-orders" element={<ProductionOrders />}></Route>
                        </Route>
                    </Route>
                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'master_admin',
                                        'office_manager',
                                        userHasPermissions(['production-orders-view', 'production-orders-update', 'production-order-history']) && role
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="production-order/:id" element={<UpdateProductionOrder />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['production_supervisor']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="production-order/:id/process" element={<ProcessProductionOrder />}></Route>
                        </Route>
                    </Route>

                    {/* Another pages and portals */}
                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'master_admin',
                                        'office_manager',
                                        'dispatcher',
                                        userHasPermissions(['dispatch']) && role
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="dispatch" element={<Dispach />}></Route>
                        </Route>
                    </Route>
                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'master_admin',
                                        'office_manager',
                                        'dispatcher',
                                        userHasPermissions(['dispatch']) && role
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="dispatch-calendar" element={<CalenderDispatch />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager', 'driver']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="driver-portal" element={<Navigate replace={true} to='available-loads' />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'forklift_driver']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="forklifter-portal" element={<Navigate replace={true} to='available-loads' />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'client']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="client-portal" element={<ClientDashboard />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth
                        allowedRoles={['master_admin', 'office_manager', 'production_supervisor', 'driver', 'forklift_driver', 'dispatcher', 'salesperson', 'client', 'customers_user']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="dashboard" element={<Dashboard />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager', 'forklift_driver', 'driver']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="available-loads" element={<AvailableLoads />}></Route>
                        </Route>
                    </Route>


                    {/* PRODUCTION MODULE */}
                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'master_admin',
                                        'office_manager',
                                        'production_supervisor',
                                        userHasPermissions(['production-data-entry']) && role
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="enter-production-data" element={<EnterProductionData />}></Route>
                        </Route>
                    </Route>

                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="production-types" element={<ProductionTypes />}></Route>
                        </Route>
                    </Route>

                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="production-types/:id" element={<UpdateProductionType />}></Route>
                        </Route>
                    </Route>

                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager', 'production_supervisor']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="production-employees" element={<ProductionEmployees />}></Route>
                        </Route>
                    </Route>

                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager', 'production_supervisor']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="production-employees/:id/edit" element={<UpdateProductionEmployee />}></Route>
                        </Route>
                    </Route>

                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager', 'production_supervisor']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="production-employees/create" element={<CreateProductionEmployee />}></Route>
                        </Route>
                    </Route>

                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager', 'production_supervisor']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="production-items" element={<ProductionItems />}></Route>
                        </Route>
                    </Route>

                    {/* CUSTOMER MODULE */}
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="contacts" element={<Contacts />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="contacts/:id" element={<UpdateContacts />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />}>
                            <Route path="customer-user/:id" element={<UpdateInsightsUser />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['customers_user']} />}>
                        <Route element={<RequireSite />}>
                            <Route path="shipments-dashboard" element={<CustomerDashboard />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['customers_user']} />}>
                        <Route element={<RequireSite />}>
                            <Route path="collections-dashboard" element={<CollectionDashboard />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['customers_user']} />}>
                        <Route element={<RequireSite />}>
                            <Route path="requests" element={<MyRequests />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['customers_user']} />}>
                        <Route element={<RequireSite />}>
                            <Route path="proceeded-shipments" element={<ProceededShipments />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['customers_user']} />}>
                        <Route element={<RequireSite />}>
                            <Route path="proceeded-collections" element={<ProceededCollections />}></Route>
                        </Route>
                    </Route>

                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />}>
                            <Route path="customer-requests" element={<CustomerRequests />}></Route>
                        </Route>
                    </Route>

                    {/* Reports */}
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="reports" element={<Reports />}></Route>
                        </Route>
                    </Route>

                    {/* Map */}
                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'master_admin',
                                        'office_manager',
                                        'dispatcher',
                                        userHasPermissions(['map']) && role
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="map" element={<Map />}></Route>
                        </Route>
                    </Route>

                    {/* Inventory */}
                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'master_admin',
                                        'office_manager',
                                        'production_supervisor',
                                        userHasPermissions(['inventory-sheets-update']) && role
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="enter-counts" element={<EnterCounts />}></Route>
                        </Route>
                    </Route>
                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'master_admin',
                                        'office_manager',
                                        'production_supervisor',
                                        userHasPermissions(['inventory-sheets-view', 'inventory-sheets-update']) && role
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="enter-counts/:id" element={<UpdateCount />}></Route>
                        </Route>
                    </Route>
                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'master_admin',
                                        'office_manager',
                                        'production_supervisor',
                                        userHasPermissions(['inventory-sheets-view', 'inventory-sheets-update']) && role
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="inventory-sheets" element={<CountsList />}></Route>
                        </Route>
                    </Route>

                    {/* 3PL and Broker (Sales Order) */}
                    {['3pl', 'broker-sales'].map((path, index) => (
                        <Route element={<RequireLogout />} key={`ext-login-route-${index}`} >
                            <Route path={path} element={<LoginExternal />}></Route>
                        </Route>
                    ))}

                    <Route path='logout' element={<Logout />} />

                    <Route element={<RequireAuth allowedRoles={['tpl_driver']} />}>
                        <Route path="3pl/:id" element={<AvailableLoadsExternal />}></Route>
                    </Route>

                    <Route element={<RequireAuth allowedRoles={['service_provider', 'vendor_user', 'service_provider_user']} />}>
                        <Route path='broker-sales/:id' element={<AvailableLoadsExternal />}></Route>
                    </Route>

                    {/* BROKER TRANSACTIONS */}
                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'master_admin',
                                        'office_manager',
                                        userHasPermissions(['daily-board-view', 'daily-board-update']) && role
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="broker-transactions" element={<BrokerTransactionsDailyBoard />}></Route>
                        </Route>
                    </Route>
                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'master_admin',
                                        'office_manager',
                                        userHasPermissions(['daily-board-view', 'daily-board-update']) && role
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="broker-transactions-calendar" element={<CalendarBroker />}></Route>
                        </Route>
                    </Route>
                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={
                                    [
                                        'master_admin',
                                        'office_manager',
                                        userHasPermissions(['daily-board-view', 'daily-board-update', 'dispatch']) && role
                                    ]
                                }
                            />
                        }
                    >
                        <Route element={<RequireSite />} >
                            <Route path="broker-transactions/:id" element={<UpdateBrokerTransaction />}></Route>
                        </Route>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={['master_admin', 'office_manager']} />}>
                        <Route element={<RequireSite />} >
                            <Route path="admin-broker-transactions" element={<BrokerTransactions />}></Route>
                        </Route>
                    </Route>

                    <Route path="*" element={<Error />} />

                </Routes>
            </BrowserRouter>
        </>
    )
}

export default App
