import React, { useState } from 'react'
import Box from '@mui/material/Box';
import { Card, CardContent, Typography, CircularProgress, Chip, Stack, Tooltip } from '@mui/material';

export default function InventoryStatusCard({currentQuantity = 0, startingQuantity = 0, itemName = 'Item', itemColor = '#1976d2', unitOfMeasureName = 'uom'}) {
    const [isHovered, setIsHovered] = useState(false)


    const pct = Number(startingQuantity) != 0 ? Math.round(((Number(currentQuantity) / Number(startingQuantity))) * 100) : 100;

    return (
        <Card
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
                overflow: 'visible',
                borderRadius: '8px',
                boxShadow: `0px 1px 2px 0px ${isHovered ? itemColor : "#1018280F"}, 0px 1px 2px 0px ${isHovered ? itemColor : "#1018280F"}, 0px -1px 4px 0px ${isHovered ? itemColor : "#1018280F"}`
            }}
        >
            <CardContent
                sx={{
                    height: '81px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: '16px !important',
                    paddingLeft: '28px !important',
                    gap: '14px'
                }}
            >
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <span
                        style={{
                            height: "100px",
                            width: "100px",
                            backgroundColor: "#ffffff",
                            borderRadius: "50%",
                            display: "inline-block",
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%, -50%)',
                            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                            boxShadow: `0px 1px 2px 0px ${isHovered ? itemColor : "#1018280F"}, 0px 1px 2px 0px ${isHovered ? itemColor : "#1018280F"}, 0px -1px 4px 0px ${isHovered ? itemColor : "#1018280F"}`,
                            zIndex: 1
                        }}
                    ></span>
                    <span
                        style={{
                            height: "79px",
                            width: "115px",
                            backgroundColor: "#ffffff",
                            display: "inline-block",
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 2
                        }}
                    ></span>

                    <CircularProgress
                        size={76}
                        variant='determinate'
                        value={100}
                        sx={{
                            display: "inline-block",
                            position: 'absolute',
                            transform: 'translate(-50%, -50%)',
                            color: '#D9D9D9',
                            zIndex: 2
                        }}
                    />
                    <CircularProgress size={76} variant='determinate' value={pct <= 100 ? pct : 100} sx={{color: itemColor, zIndex: 3}} />

                    <Tooltip
                        title={
                            <Stack textAlign='center'>
                                <Typography>{currentQuantity}</Typography>
                                <Typography variant='caption'>{unitOfMeasureName}</Typography>
                            </Stack>
                        }
                        sx={{
                            textAlign: 'center'
                        }}
                        arrow
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -28]
                                        }
                                    }
                                ]
                            }
                        }}
                    >
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 3
                            }}
                        >
                            <Stack direction='column' alignItems='center'>
                                <Typography component="div" color='#000000' fontWeight='500' fontSize='14px' lineHeight='normal'>
                                    {currentQuantity < 1000000 ? currentQuantity : "+999999"}
                                </Typography>

                                <Typography
                                    width='55.29px'
                                    textAlign='center'
                                    textOverflow='ellipsis'
                                    overflow='hidden'
                                    whiteSpace='nowrap'
                                    fontSize='9px'
                                    fontWeight='500'
                                    component='div'
                                >
                                    {unitOfMeasureName}
                                </Typography>
                            </Stack>
                        </Box>
                    </Tooltip>
                </Box>
                <Typography
                    sx={{
                        flex: '1 1 0%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '3',
                        WebkitBoxOrient: 'vertical',
                    }}
                    textAlign='center'
                    zIndex='3'
                    fontWeight='500'
                    fontSize='15px'
                    lineHeight='24px'
                    color='#667085'
                    component='div'
                >
                    {itemName}
                </Typography>
            </CardContent>
        </Card>
    )
}
