import React from 'react'
import InventoryStatusCard from './utils/InventoryStatusCard';
import { Box, Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Loading from '../Loading';
import { useStateContext } from '../../context/ContextProvider';

export default function InventoryStatusScrollableRow({data = [], isLoading = false, isUpdating = false, isOpen = true}) {
    const { features } = useStateContext()
    const { t } = useTranslation()

    const getInventoryItemName = (inventoryItem) => {
        if(features?.ITEM_NO_DESCRIPTION_SWITCH == 1) {
            return inventoryItem?.item?.description ?? 'Item';
        } else {
            return inventoryItem?.item?.item_no ?? 'Item';
        }
    }

    return (
        <Box sx={{position: 'relative', minHeight: '100px', display: isOpen ? 'block' : 'none'}}>
            {isUpdating ? (
                <Loading position='absolute' overflow='hidden' />
            ) : (
                ''
            )}

            {isLoading ? (
                <Skeleton variant='rectangular' sx={{width: '100%'}} height={81} />
            ) : data.length > 0 ? (
                <Box
                    height={120}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        paddingLeft: '14px',
                        paddingRight: '14px',
                        alignItems: 'center',
                        gap: '14px',
                        overflowX: 'scroll',
                        overflowY: 'visible'
                    }}
                >
                    {
                        data?.map((inventoryItem, index) => (
                            <Box
                                key={`iiid${inventoryItem.id}-${index}`}
                                minWidth={310}
                            >
                                <InventoryStatusCard
                                    currentQuantity={inventoryItem?.current_perpetual_inventory ?? 0}
                                    startingQuantity={inventoryItem?.starting_perpetual_inventory ?? 0}
                                    itemName={getInventoryItemName(inventoryItem)}
                                    itemColor={inventoryItem?.item?.color ?? '#000000'}
                                    unitOfMeasureName={inventoryItem?.item?.unit_of_measure?.code ?? 'uom'}
                                />
                            </Box>

                        ))
                    }
                </Box>
            ) : (
                <Box
                    height={120}
                    sx={{
                        display: 'flex',
                        paddingLeft: '14px',
                        paddingRight: '14px',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Typography variant='body2'>
                        {t('no_items_of_this_type_found')}
                    </Typography>
                </Box>
            )}
        </Box>
    )
}
