import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { useStateContext } from '../../context/ContextProvider'
import { includes } from 'lodash'

const CombinedNavigation = ({
    user,
    shipmentsChecked = true,
    collectionsChecked = true,
    transactionsChecked = true,
    setShipmentsChecked = () => {},
    setCollectionsChecked = () => {},
    setTransactionsChecked = () => {}
}) => {
    const { menuItems } = useStateContext();

    const userHasPermissions = (targets = []) => {
        return user?.permissions.some((permission) => targets.includes(permission.name));
    }

    const [showSalesOrdersTab, setShowSalesOrdersTab] = useState(false)
    const [showPurchaseOrdersTab, setShowPurchaseOrdersTab] = useState(false)
    const [showBrokerTransactionsTab, setShowBrokerTransactionsTab] = useState(false)

    useEffect(() => {
        if(menuItems.length > 0) {
            const salesOrdersMenu = menuItems.find((mi) => mi.url === (user?.role != 'vendor_user' ? '/sales-order' : '/sales-orders'))
            const purchaseOrdersMenu = menuItems.find((mi) => mi.url === (user?.role != 'vendor_user' ? '/purchase-order' : '/purchase-orders'))
            const brokerTransactionsMenu = menuItems.find((mi) => mi.url === '/broker-transactions')

            setShowSalesOrdersTab(salesOrdersMenu?.is_active == 1 ? true : false)
            setShowPurchaseOrdersTab(purchaseOrdersMenu?.is_active == 1 ? true : false)
            setShowBrokerTransactionsTab(brokerTransactionsMenu?.is_active == 1 ? true : false)
        }
    }, [menuItems])

    return (
        <div className='w-full flex flex-row flex-wrap items-center gap-x-1 gap-y-4 p-6 pt-0'>
            <div className='flex grow items-center justify-center px-1 rounded-md bg-[#ededf1]' style={{ boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.05) inset, 0px 0px 4px 0px rgba(0, 0, 0, 0.05) inset, 0px 0px 2px 0px rgba(0, 0, 0, 0.05) inset' }}>
                {
                    (
                        showSalesOrdersTab
                        || userHasPermissions(['daily-board-view', 'daily-board-update'])
                    ) ? (
                        <Link to={user?.role != 'vendor_user' ? '/sales-order' : '/sales-orders'} className='w-full'>
                            <div className={`w-full text-center m-1 ml-0
                            ${includes(['/sales-order', '/sales-orders', '/sales-order-calendar', '/sales-orders-calendar'], window.location.pathname) ? 'bg-white rounded-sm' : 'border-r-2'}`}
                                style={{
                                    border: includes(['/sales-order', '/sales-orders'], window.location.pathname) ? '0.5px solid rgba(218, 218, 218, 0.02)' : '',
                                    boxShadow: includes(['/sales-order', '/sales-orders'], window.location.pathname) ? '0px 1px 0.75px 0px rgba(0, 0, 0, 0.05), 0px 0.25px 0.25px 0px rgba(0, 0, 0, 0.10)' : ''
                                }}>
                                <p className='p-1.5'>{t('sales_orders')}</p>
                            </div>
                        </Link>
                    ) : null
                }

                {
                    (
                        showPurchaseOrdersTab
                        || userHasPermissions(['daily-board-view', 'daily-board-update'])
                    ) ? (
                        <Link to={user?.role != 'vendor_user' ? '/purchase-order' : '/purchase-orders'} className='w-full'>
                            <div className={`w-full text-center m-1 ml-0 mr-0
                            ${includes(['/purchase-order', '/purchase-orders', '/purchase-order-calendar', '/purchase-orders-calendar'], window.location.pathname) ? 'bg-white rounded-sm' : ''}`}
                                style={{
                                    border: includes(['/purchase-order', '/purchase-orders'], window.location.pathname) ? '0.5px solid rgba(218, 218, 218, 0.02)' : '',
                                    boxShadow: includes(['/purchase-order', '/purchase-orders'], window.location.pathname) ? '0px 1px 0.75px 0px rgba(0, 0, 0, 0.05), 0px 0.25px 0.25px 0px rgba(0, 0, 0, 0.10)' : ''
                                }}>
                                <p className='p-1.5'>{t('purchase_orders')}</p>
                            </div>
                        </Link>
                    ) : null
                }

                {
                    (
                        (showBrokerTransactionsTab && includes(['master_admin', 'office_manager'], user?.role))
                        || userHasPermissions(['daily-board-view', 'daily-board-update'])
                    ) ? (
                        <Link to='/broker-transactions' className='w-full'>
                            <div className={`w-full text-center m-1 ml-0
                            ${window.location.pathname == '/broker-transactions' || window.location.pathname == '/broker-transactions-calendar' ? 'bg-white rounded-sm' : 'border-l-2'}`}
                                style={{
                                    border: window.location.pathname == '/broker-transactions' ? '0.5px solid rgba(218, 218, 218, 0.02)' : '',
                                    boxShadow: window.location.pathname == '/broker-transactions' ? '0px 1px 0.75px 0px rgba(0, 0, 0, 0.05), 0px 0.25px 0.25px 0px rgba(0, 0, 0, 0.10)' : ''
                                }}>
                                <p className='p-1.5'>{t('broker_transactions')}</p>
                            </div>
                        </Link>
                    ) : null
                }
            </div>

            <div>
                <FormGroup row={true} sx={{marginLeft: '14px', flexWrap: { xs: 'wrap', lg: 'nowrap' }}}>
                    {
                        (
                            showSalesOrdersTab
                            || userHasPermissions(['daily-board-view', 'daily-board-update'])
                        ) && (
                            <FormControlLabel
                                control={<Checkbox checked={shipmentsChecked} onChange={(e) => setShipmentsChecked(e.target.checked)} />}
                                label={t('shipments')}
                            />
                        )
                    }

                    {
                        (
                            showPurchaseOrdersTab
                            || userHasPermissions(['daily-board-view', 'daily-board-update'])
                        ) && (
                            <FormControlLabel
                                control={<Checkbox checked={collectionsChecked} onChange={(e) => setCollectionsChecked(e.target.checked)} />}
                                label={t('collections')}
                            />
                        )
                    }

                    {/* {{
                        (
                            (showBrokerTransactionsTab && includes(['master_admin', 'office_manager'], user?.role))
                            || userHasPermissions(['daily-board-view', 'daily-board-update'])
                        ) && (
                            <FormControlLabel
                                control={<Checkbox checked={transactionsChecked} onChange={(e) => setTransactionsChecked(e.target.checked)} />}
                                label={t('transactions')}
                            />
                        )
                    }} */}
                </FormGroup>
            </div>
        </div >
    )
}

export default CombinedNavigation
