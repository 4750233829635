import { Box, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Modal, Radio, RadioGroup, Typography } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import { MobileDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const dayObjects = [
    {
        id: 0,
        name: 'sunday'
    },
    {
        id: 1,
        name: 'monday'
    },
    {
        id: 2,
        name: 'tuesday'
    },
    {
        id: 3,
        name: 'wednesday'
    },
    {
        id: 4,
        name: 'thursday'
    },
    {
        id: 5,
        name: 'friday'
    },
    {
        id: 6,
        name: 'saturday'
    }
];

const RecurrenceConfigurationModal = ({
    open,
    handleClose,
    handleOK,
    recurrenceState
}) => {
    const [recurrenceStateCopy, setRecurrenceStateCopy] = useState(recurrenceState);

    const today = dayjs().endOf('day');
    const { from, to, pattern, days } = recurrenceStateCopy;

    useEffect(() => {
        if(!from.isSame(to) && from.isAfter(to)) {
            setRecurrenceStateCopy((prev) => ({
                ...prev,
                to: from
            }));
        }
    }, [from, to])

    const handleClickClose = (event, reason) => {
        handleClose(event, reason);
        setRecurrenceStateCopy(recurrenceState);
    }

    const handleClickOK = () => {
        handleOK({
            ...recurrenceStateCopy,
            days: recurrenceStateCopy.days.sort((a, b) => a.id - b.id)
        });
    }

    const onChangePattern = (event) => {
        setRecurrenceStateCopy((prev) => ({
            ...prev,
            pattern: event.target.value,
            days: []
        }))
    }

    const dayIsChecked = (day, days) => {
        return days
            .map((d) => d.id)
            .indexOf(day.id) > -1;
    }

    const checkDay = (event, day, days) => {
        if(event.target.checked && !dayIsChecked(day, days)) {
            setRecurrenceStateCopy((prev) => ({
                ...prev,
                days: days.concat([day])
            }));
        } else {
            setRecurrenceStateCopy((prev) => ({
                ...prev,
                days: days.filter((d) => d.id !== day.id)
            }));
        }
    }

    return (
        <Modal
            open={open}
            onClose={(event, reason) => handleClickClose(event, reason)}
        >
            <Box sx={style}>
                <div className='p-5'>
                    <div className='flex flex-col justify-between pb-5 w-full' style={{ borderRadius: '5px 5px 0 0' }}>
                        <div className='flex items-baseline justify-between w-full'>
                            <p className='text-[18px] font-[600] text-[#303030]'>{t('recurrence_x', {x: t('configuration')})}</p>
                            <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[18px] text-[#303030]">
                                <button onClick={handleClickClose}><i className="fa-solid fa-plus"></i></button>
                            </div>
                        </div>

                        <FormControl className="!flex !flex-col !gap-3 !my-5 !pb-5">
                            <div className={`pb-1`}>
                                <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('recurrence_x', {x: t('pattern')})}</p>
                            </div>

                            <div className="flex md:flex-row flex-col gap-4 pb-1">
                                <RadioGroup
                                    name="recurrence-patern-radio-group"
                                    value={pattern}
                                    onChange={onChangePattern}
                                    className="md:border-r-[1px] md:border-b-0 border-r-0 border-b-[1px] border-gray-300"
                                >
                                    <FormControlLabel value="weekly" control={<Radio />} label={t('weekly')} />
                                </RadioGroup>

                                <FormGroup className="!flex-grow-0 !flex-row">
                                    {dayObjects.map((day) => (
                                         <FormControlLabel
                                            key={`day-checkbox-${day.id}`}
                                            control={<Checkbox checked={dayIsChecked(day, days)} onChange={(e) => checkDay(e, day, days)} />}
                                            label={t(day.name)}
                                        />
                                    ))}
                                </FormGroup>
                            </div>

                            <Divider />

                            <div className={`pb-1`}>
                                <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('range_of_x', {x: t('recurrence')})}</p>
                            </div>

                            <div className="flex md:flex-row flex-col gap-2">
                                <div className="flex flex-row gap-2 w-full md:w-1/2 items-center">
                                    <Typography>
                                        Start from:
                                    </Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker
                                            sx={{
                                                background: '#FCFCFC',
                                                boxShadow: 'none',
                                                '.MuiOutlinedInput-input': {
                                                    padding: '9px !important'
                                                },
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    border: '1px solid #EEEFF2 !important'
                                                },
                                                flexGrow: 1
                                            }}
                                            format="YYYY-MM-DD"
                                            value={from}
                                            minDate={today}
                                            onChange={(newValue) => setRecurrenceStateCopy((prev) => ({...prev, from: newValue}))}
                                        />
                                    </LocalizationProvider>
                                </div>

                                <div className="flex flex-row gap-2 w-full md:w-1/2 items-center">
                                    <Typography>
                                        End by:
                                    </Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker
                                            sx={{
                                                background: '#FCFCFC',
                                                boxShadow: 'none',
                                                '.MuiOutlinedInput-input': {
                                                    padding: '9px !important'
                                                },
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    border: '1px solid #EEEFF2 !important'
                                                },
                                                flexGrow: 1
                                            }}
                                            format="YYYY-MM-DD"
                                            value={to}
                                            minDate={from}
                                            onChange={(newValue) => setRecurrenceStateCopy((prev) => ({...prev, to: newValue}))}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </FormControl>

                        <div className='w-full flex gap-2 justify-end'>
                            <button type="button" onClick={handleClickOK} className='min-w-[120px] text-[14px] text-white rounded-sm px-4 py-2 bg-[#6C6C6C] hover:bg-[#494949] disabled:bg-[#B8B7BC] capitalize'>
                                {t('OK')}
                            </button>
                            <button type="button" onClick={handleClickClose} className='min-w-[120px] text-[14px] text-[#919399] rounded-sm px-4 py-2 bg-[#F3F5F7] hover:bg-[#E6E6E6] disabled:bg-[#B8B7BC] capitalize'>
                                {t('cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default RecurrenceConfigurationModal;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: "900px",
    height: 'fit-content',
    bgcolor: '#ffffff',
    border: 'transparent',
    borderRadius: '5px',
    boxShadow: 24,
    zIndex: "1600",
    outline: 'none'
}
