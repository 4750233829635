import React, {useState} from 'react'
import {Box} from '@mui/material'
import {DataGrid, GridToolbarColumnsButton, GridToolbarContainer} from '@mui/x-data-grid'
import {useTranslation} from 'react-i18next'
import axios from '../lib/axios'
import Swal from 'sweetalert2'
import {useAuth} from '../hooks/auth';
import { getTableSharedSx } from '../helpers/helper'

const TableSharedCustom = ({
    items,
    columns,
    rowsPerPageOptions = [10, 15],
    density = "standard",
    disableColumnMenu = false,
    hideSortingButton = false,
    breakHeaders = false,
    dynamicRowHeight = false,
    hideFooter = false,
    oddColumnPadding = '10px',
    headerRowFontSize = '0.875rem',
    flat = false,
    columnGrouping = [],
    border = '',
    columnVisibilityModel,
    setModel,
    view = ''
}) => {

    const [pageSize, setPageSize] = useState(rowsPerPageOptions[0])
    const { t } = useTranslation()


    const localizedTextsMap = {
        toolbarColumns: t('custom_view'),
        columnsPanelShowAllButton: t('show_all'),
        columnsPanelHideAllButton: t('hide_all'),
        columnsPanelTextFieldLabel: t('find_column'),
        columnsPanelTextFieldPlaceholder: t('column_title'),
        columnsPanelDragIconLabel: t('reorder_column'),
        columnMenuUnsort: t('unsort'),
        columnMenuSortAsc: t('sortAsc'),
        columnMenuSortDesc: t('sortDesc'),
        columnMenuFilter: t('filter'),
        columnMenuHideColumn: t('hide'),
        columnMenuShowColumns: t('showCol'),
        MuiTablePagination: {
            labelRowsPerPage: t('rowsPerPage'),
            labelDisplayedRows: ({ from, to, count }) =>
                `${from} - ${to} ${t('of')} ${count}`,

        },
        noRowsLabel: t('noRows')
    }

    return (
        <div>
            <Box sx={{ width: '100%' }}>
                <DataGrid
                    sx={getTableSharedSx({
                        breakHeaders,
                        hideSortingButton,
                        oddColumnPadding,
                        headerRowFontSize,
                        flat
                    })}
                    disableColumnMenu
                    autoHeight
                    rows={items}
                    columns={columns}
                    pageSize={pageSize}
                    components={{
                        Toolbar: () => CustomToolbar(view, columnVisibilityModel),
                        ColumnSortedAscendingIcon: () => <div className='rotate-90'><i className="fa-solid fa-arrow-right-arrow-left"></i></div>,
                        ColumnSortedDescendingIcon: () => <div className='rotate-90'><i className="fa-solid fa-arrow-right-arrow-left"></i></div>,
                    }}
                    componentsProps={{
                        panel: {
                            placement: "bottom-end"
                        }
                    }}
                    density={density}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={newModel => setModel(newModel)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={rowsPerPageOptions}
                    getRowHeight={() => dynamicRowHeight ? 'auto' : null}
                    getEstimatedRowHeight={() => 52}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true, columnGrouping: columnGrouping.length > 0 ? true : false }}
                    columnGroupingModel={columnGrouping.length > 0 ? [
                        ...columnGrouping
                    ] : []}
                    localeText={localizedTextsMap}
                    hideFooter={hideFooter}
                />
            </Box>
        </div>
    )
}

export default TableSharedCustom

function CustomToolbar(view, model) {

    const { t } = useTranslation()
    const {user} = useAuth({middleware: 'guest'})

    const saveView = async () => {
        try {
            const formData = {
                view: view,  // view name
                model: model,
                user_id: user?.id
            };

            const response = await axios.post(`/api/store-table-view`, formData);

            if (response.status === 200) {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: response.data.success.message,
                });
            } else {
                Swal.fire({
                    text: response.data.message || "An error occurred",
                    icon: "error",
customClass: 'error',
showCloseButton: true,
iconColor: '#FF0000',
                });
            }
        } catch (error) {
            Swal.fire({
                text: error.message || "An error occurred",
                icon: "error",
customClass: 'error',
showCloseButton: true,
iconColor: '#FF0000',
            });
        }
    };

    return (
        <GridToolbarContainer sx={{ justifyContent: 'end', gap: '5px' }}>
            <GridToolbarColumnsButton startIcon={<i className="fa-solid fa-gear"></i>} />
            <button onClick={saveView} className='bg-green-500 rounded-md text-white py-1 px-2 uppercase text-xs'><i className="fa-solid fa-pen-to-square"></i> {t('save_view')}</button>
        </GridToolbarContainer>
    );
}
