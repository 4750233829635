import React, { useState, useEffect, useRef } from 'react'

import { Link, useNavigate, useLocation } from 'react-router-dom'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import update from 'immutability-helper'
import { Tooltip } from '@mui/material'

import AppLayout from '../Layouts/AppLayout'
import { useTranslation } from 'react-i18next'
import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import '../../DispatchCalendar.css'
import Loading from '../Loading'
import { isValid } from '../../helpers/helper'
import i18next from 'i18next'
import allLocales from '@fullcalendar/core/locales-all'
import NavigationDispatch from './NavigationDispatch'
import { SALES_ORDER_STAUSES, PURCHASE_ORDER_STAUSES } from '../../constants/constants'
import { includes } from 'lodash'
import EventItems from '../common/calendar/EventItems'

const CalenderDispatch = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const { config, choosesite, setChoosesite, pusher } = useStateContext()
    const [isLoading, setIsLoading] = useState(false)

    const calendarRef = useRef([])

    const [salesOrders, setSalesOrders] = useState([])
    const [purchaseOrders, setPurchaseOrders] = useState([])

    const [shipmentsChecked, setShipmentsChecked] = useState(() => {
        if(localStorage.getItem('filters') !== null) {
            const filters = JSON.parse(localStorage.getItem('filters'))
            return filters[0]?.dispatch_calendar_shipments_checked ?? true
        } else {
            return true
        }
    })
    const [collectionsChecked, setCollectionsChecked] = useState(() => {
        if(localStorage.getItem('filters') !== null) {
            const filters = JSON.parse(localStorage.getItem('filters'))
            return filters[0]?.dispatch_calendar_collections_checked ?? true
        } else {
            return true
        }
    })
    const [initialView, setInitialView] = useState(() => {
        if(localStorage.getItem('filters') !== null) {
            const filters = JSON.parse(localStorage.getItem('filters'))
            if(filters[0]?.calendar_initial_view && includes(['dayGridMonth', 'timeGridWeek', 'timeGridDay'], filters[0]?.calendar_initial_view)) {
                return filters[0]?.calendar_initial_view
            } else {
                return 'dayGridMonth'
            }
        } else {
            return 'dayGridMonth'
        }
    })

    const [events, setEvents] = useState([])

    useEffect(() => {
        // Unsubscribe on unmount
        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${localStorage.getItem('site')}`)
        })
    }, [])

    useEffect(() => {
        if(isValid(choosesite)) {
            getSalesOrders(choosesite)
            getPurchaseOrders(choosesite)
        }
    }, [choosesite])

    useEffect(() => {
        if (isValid(choosesite)) {
            const channelSalesOrderDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${choosesite}`)
            const channelSalesOrderUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${choosesite}`)
            const channelPurchaseOrderDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${choosesite}`)
            const channelPurchaseOrderUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${choosesite}`)

            channelSalesOrderDelete.bind(`${localStorage.getItem('client_id')}-salesorder-deleted-event-site-${choosesite}`, data => {
                setSalesOrders((prev) => {
                    const item = prev.find((i) => i.id === data.id)
                    const exItem = prev.filter((i) => i.id !== item.id)
                    return exItem
                })
            })

            channelSalesOrderUpdate.bind(`${localStorage.getItem('client_id')}-salesorder-updated-event-site-${choosesite}`, data => {
                if(data?.order_type === "SHIPMENT") {
                    getSalesOrder(data.id)
                }
            })

            channelPurchaseOrderDelete.bind(`${localStorage.getItem('client_id')}-purchaseorder-deleted-event-site-${choosesite}`, data => {
                setPurchaseOrders((prev) => {
                    const item = prev.find((i) => i.id === data.id)
                    const exItem = prev.filter((i) => i.id !== item.id)
                    return exItem
                })
            })

            channelPurchaseOrderUpdate.bind(`${localStorage.getItem('client_id')}-purchaseorder-updated-event-site-${choosesite}`, data => {
                if(data?.order_type === "COLLECTION") {
                    getPurchaseOrder(data.id)
                }
            })
        }

        return (() => {
            if(isValid(choosesite)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${choosesite}`)
            }
        })
    }, [choosesite])

    useEffect(() => {
        setEvents([])

        salesOrders.map((so) => {
            const newEvent =  {
                "order_id": so.id,
                "order_type": "sales",
                "title": so.so_number,
                "date": so.requested_delivery_date + 'T' + so.requested_delivery_time_time,
                "requested_delivery_date": so.requested_delivery_date,
                "customer_name": so.customer_name,
                "ship_address_name": so.ship_address_name,
                "items": so?.sales_order_items
            }

            setEvents((prev) => [...prev, newEvent])
        })

        purchaseOrders.map((po) => {
            const newEvent = {
                "order_id": po.id,
                "order_type": "purchase",
                "title": po.po_number,
                "date": po.requested_collection_date + 'T' + po.requested_collection_time_time,
                "requested_collection_date": po.requested_collection_date,
                "vendor_name": po.vendor_name,
                "purchase_address_name": po.purchase_address_name,
                "items": po?.purchase_order_items
            }

            setEvents((prev) => [...prev, newEvent])
        })
    }, [salesOrders, purchaseOrders])

    useEffect(() => {
        createFilterArray(shipmentsChecked, collectionsChecked)
    }, [shipmentsChecked, collectionsChecked])

    {/* Functions */}

    const createFilterArray = (shipmentsChecked, collectionsChecked) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].dispatch_calendar_shipments_checked = shipmentsChecked
        filters[0].dispatch_calendar_collections_checked = collectionsChecked

        localStorage.setItem('filters', JSON.stringify(filters))
    }

    const getSalesOrders = async (siteId = null) => {
        setEvents([])
        setIsLoading(true)
        await axios
            .get(`/api/list-orders?order_type=sales&site_id=${isValid(siteId) ? siteId : localStorage.getItem('site')}&status_id=${SALES_ORDER_STAUSES['RELEASED']},${SALES_ORDER_STAUSES['ASSIGNED_TO_FORK']},${SALES_ORDER_STAUSES['LOADING']},${SALES_ORDER_STAUSES['LOADED']}&type=SHIPMENT,BROKERED`, config)
            .then(res => {
                const data = res.data?.data;
                setSalesOrders(data)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getSalesOrder = async (id) => {
        setIsLoading(true)

        await axios.get(`/api/sales-orders/${id}`, config)
            .then(res => {
                const salesOrder = res.data

                if(salesOrder.type === 'SHIPMENT') {
                    setSalesOrders((prev) => {
                        const index = prev?.findIndex((i) => i.id === id)
                        if (index < 0) {
                            if(salesOrder.so_status_id >= 2 && salesOrder.so_status_id < 7) {
                                const items = [...prev, salesOrder]
                                return items
                            }

                            return prev
                        } else {
                            if (salesOrder.so_status_id > 6) {
                                return update(prev,
                                    { $splice: [[index, 1]] }
                                )
                            } else {
                                return update(prev,
                                    { [index]: { $set: salesOrder } }
                                )
                            }
                        }
                    })
                }

            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getPurchaseOrders = async (siteId = null) => {
        setEvents([])
        setIsLoading(true)
        await axios
            .get(`/api/list-orders?order_type=purchase&site_id=${isValid(siteId) ? siteId : localStorage.getItem('site')}&status_id=${PURCHASE_ORDER_STAUSES['RELEASED']}&type=COLLECTION`, config)
            .then(res => {
                const data = res.data?.data
                setPurchaseOrders(data)
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getPurchaseOrder = async (id) => {
        setIsLoading(true)

        await axios.get(`/api/purchase-orders/${id}`, config)
            .then(res => {
                const purchaseOrder = res.data

                if(purchaseOrder.type === 'COLLECTION') {
                    setPurchaseOrders((prev) => {
                        const index = prev?.findIndex((i) => i.id === id)
                        if (index < 0) {
                            if(purchaseOrder.po_status_id == 2) {
                                const items = [...prev, purchaseOrder]
                                return items
                            }

                            return prev
                        } else {
                            if (purchaseOrder.po_status_id !== 2) {
                                return update(prev,
                                    { $splice: [[index, 1]] }
                                )
                            } else {
                                return update(prev,
                                    { [index]: { $set: purchaseOrder } }
                                )
                            }
                        }
                    })
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleEventClick = (eventInfo) => {
        const orderType = eventInfo?.event.extendedProps.order_type

        if(orderType === 'sales') {
            localStorage.setItem('initialDateSales', eventInfo.event.extendedProps.requested_delivery_date)
            navigate(`/sales-order/${eventInfo.event.extendedProps.order_id}`, { state: { route: '/dispatch-calendar', prevPathname: location.pathname } });
        } else if (orderType === 'purchase') {
            localStorage.setItem('initialDatePurchase', eventInfo.event.extendedProps.requested_collection_date)
            navigate(`/purchase-order/${eventInfo.event.extendedProps.order_id}`, { state: { route: '/dispatch-calendar', prevPathname: location.pathname } });
        }
    }

    return (
        <>
            {isLoading ? <Loading /> : null}
            <AppLayout>
                <NavigationDispatch
                    route='dispatch'
                    type='calendar'
                    shipmentsChecked={shipmentsChecked}
                    collectionsChecked={collectionsChecked}
                    setShipmentsChecked={setShipmentsChecked}
                    setCollectionsChecked={setCollectionsChecked}
                />
                <div className='p-5 w-full'>
                    <div className='p-2 bg-white mb-2 rounded-md dispatch'>
                        <FullCalendar
                            locales={allLocales}
                            locale={i18next?.resolvedLanguage ?? 'en'}
                            plugins={[dayGridPlugin, timeGridPlugin]}
                            ref={calendarRef}
                            headerToolbar={{
                                left: 'prev,next today',
                                center: 'title',
                                right: 'dayGridMonth,timeGridWeek,timeGridDay'
                            }}
                            buttonText={{
                                today: t('today'),
                                month: t('month'),
                                week: t('week'),
                                day: t('day')
                            }}
                            customButtons={{
                                today: {
                                    text: t('today'),
                                    click: () => {
                                        localStorage.removeItem('initialDatePurchase')
                                        let calendarApi = calendarRef.current.getApi()
                                        calendarApi.today()
                                    }
                                }
                            }}
                            initialView={initialView}
                            datesSet={(args) => {
                                if (localStorage.getItem('filters') === null) {
                                    let filter = [{}]
                                    localStorage.setItem('filters', JSON.stringify(filter))
                                }

                                let filters = JSON.parse(localStorage.getItem('filters'))

                                filters[0].calendar_initial_view = args.view.type

                                localStorage.setItem('filters', JSON.stringify(filters))
                            }}
                            events={events?.filter((e) => {
                                if(shipmentsChecked && !collectionsChecked) {
                                    return e.order_type === 'sales'
                                } else if(!shipmentsChecked && collectionsChecked) {
                                    return e.order_type === 'purchase'
                                } else if (!shipmentsChecked && !collectionsChecked) {
                                    return
                                }
                                return e
                            })}
                            eventContent={renderEventContent}
                            dayMaxEvents={true}
                            eventMaxStack={1}
                            expandRows={true}
                            eventColor='#ffffff'
                            initialDate={localStorage.getItem('initialDatePurchase')}
                            eventClick={handleEventClick}
                        />
                    </div>
                </div>
            </AppLayout>
        </>
    )
}

export default CalenderDispatch

function renderEventContent(eventInfo) {

    const { items, order_type, order_id } = eventInfo.event.extendedProps;

    return (
        <Tooltip disableInteractive placement='left' title={
            <div className='flex flex-col'>
                <p className='text-[16px] pb-3'>{eventInfo.event.title}</p>
                {eventInfo.event.extendedProps.order_type === 'sales' ? (
                    <>
                        <i className='text-[14px]'>{eventInfo.event.extendedProps.customer_name}</i>
                        <i className='text-[14px]'>{eventInfo.event.extendedProps.ship_address_name}</i>
                        <EventItems items={items} orderId={order_id} orderType={order_type} />
                    </>
                ) : (
                    <>
                        <i className='text-[14px]'>{eventInfo.event.extendedProps.vendor_name}</i>
                        <i className='text-[14px]'>{eventInfo.event.extendedProps.purchase_address_name}</i>
                        <EventItems items={items} orderId={order_id} orderType={order_type} />
                    </>
                )}
            </div>
        }>
            <button className='w-full'>
                <div className={`p-1 flex flex-col text-left ${eventInfo.event.extendedProps.order_type === 'sales' ? 'bg-[#336195]' : 'bg-[#b7472a]'} text-white rounded-sm`}>
                    <p className='text-xl bold p-1 roboto'>{eventInfo.event.title}</p>
                    {eventInfo.event.extendedProps.order_type === 'sales' ? (
                        <>
                            <i>{eventInfo.event.extendedProps.customer_name}</i>
                            <i>{eventInfo.event.extendedProps.ship_address_name}</i>
                            <EventItems items={items} orderId={order_id} orderType={order_type} />
                        </>
                    ) : (
                        <>
                            <i>{eventInfo.event.extendedProps.vendor_name}</i>
                            <i>{eventInfo.event.extendedProps.purchase_address_name}</i>
                            <EventItems items={items} orderId={order_id} orderType={order_type} />
                        </>
                    )}
                </div>
            </button>
        </Tooltip>
    )
}
