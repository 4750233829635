import React, {useEffect, useState} from 'react'

import {
    Box,
    Modal,
    Switch,
    Tab,
    TextField,
    Tooltip,
    IconButton,
    MenuItem,
    Select,
    Stack,
    FormControl,
    InputLabel,
    Autocomplete
} from '@mui/material'
import ClearIcon from "@mui/icons-material/Clear";
import {useTranslation} from "react-i18next";
import Swal from 'sweetalert2'
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useCountries } from 'use-react-countries'

import Loading from '../../components/Loading'
import AppLayout from '../../components/Layouts/AppLayout'
import AddButton from '../../components/AddButton'
import style from '../../styles/style'
import axios from '../../lib/axios'
import {useStateContext} from '../../context/ContextProvider'
import TableSharedCustom from '../../components/TableSharedCustom'
import {useAuth} from '../../hooks/auth'
import {Link} from 'react-router-dom'
import {handleAxiosError, isValid} from '../../helpers/helper';
import { TEXTFIELD_STYLE, SELECT_STYLE, AUTOCOMPLETE_STYLE } from '../../constants/constants';
import TableSharedPaginated from '../../components/TableSharedPaginated';
import { buildUrl } from '../../helpers/apiHelper';
import { useDebounce } from "use-debounce"

const Customers = () => {

    const { t } = useTranslation();
    const { config, companies, company_id, getCompanies: getCompaniesContext, pusher, creditTerms } = useStateContext()
    const { user } = useAuth({ middleware: 'guest' })
    const { countries } = useCountries()

    /* state */
    const [pagination, setPagination] = useState(() => {
        const data = {};

        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            data.no = filter[0]?.customer_no || ''
        } else {
            data.no = ''
        }

        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            data.name = filter[0]?.customer_name || ''
        } else {
            data.name = ''
        }

        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            data.blocked = filter[0]?.customer_blocked || ''
        } else {
            data.blocked = ''
        }

        data.page = 1
        data.rowsPerPage = 10

        return data;
    })

    const [debouncedSearchNo] = useDebounce(pagination.no, 500)
    const [debouncedSearchName] = useDebounce(pagination.name, 500)
    const [debouncedSearchBlocked] = useDebounce(pagination.blocked, 250)

    const [total, setTotal] = useState(0)
    const [open, setOpen] = useState(false);
    const [customers, setCustomers] = useState([])
    const [value, setValue] = useState('1')
    const [isLoading, setIsLoading] = useState(false)
    const [sites, setSites] = useState([])

    const [random, setRandom] = useState(null)
    const [model, setModel] = useState({
        actions: true,
        address: true,
        blocked_text: true,
        city: false,
        company_name: true,
        customer_no: true,
        email: true,
        location_name: true,
        name: true,
        state: false,
        zip: false,
        phone: false,
        fax: false,
        website: false,
        shipping_code: false,
        country: false
    })

    /* FORM FIELDS */
    /* general */
    const [no, setNo] = useState("")
    const [name, setName] = useState("")
    const [address, setAddress] = useState("")
    const [address2, setAddress2] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [zip, setZip] = useState("")
    const [country, setCountry] = useState(null)
    const [countryInputValue, setCountryInputValue] = useState('')
    const [company, setCompany] = useState(company_id)
    const [blocked, setBlocked] = useState(false)
    const [creditTermId, setCreditTermId] = useState('')
    const [creditTerm, setCreditTerm] = useState(null)
    const [creditTermInputValue, setCreditTermInputValue] = useState('')

    /* communication */
    const [phone, setPhone] = useState("")
    const [fax, setFax] = useState("")
    const [email, setEmail] = useState("")
    const [website, setWebsite] = useState("")
    const [generalCompanyEmail, setGeneralCompanyEmail] = useState('')

    const [salesContactName, setSalesContactName] = useState('')
    const [salesContactFirstName, setSalesContactFirstName] = useState('')
    const [salesContactLastName, setSalesContactLastName] = useState('')

    const [accountingContactName, setAccountingContactName]= useState('')
    const [accountingContactFirstName, setAccountingContactFirstName] = useState('')
    const [accountingContactLastName, setAccountingContactLastName] = useState('')
    const [accountingContactPhoneNo, setAccountingContactPhoneNo] = useState('')
    const [accountingEmail, setAccountingEmail] = useState('')

    const [orderDeskContactName, setOrderDeskContactName] = useState('')
    const [orderDeskContactFirstName, setOrderDeskContactFirstName] = useState('')
    const [orderDeskContactLastName, setOrderDeskContactLastName] = useState('')
    const [orderDeskEmail, setOrderDeskEmail] = useState('')

    /* shipping */
    const [site, setSite] = useState("")
    const [code, setCode] = useState("")

    useEffect(() => {

        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-customer-deleted`)
        const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-customer-created`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-customer-updated`)

        channeldelete.bind(`${localStorage.getItem('client_id')}-customer-deleted-event`, data => {
            setRandom(Math.random())
        })

        channelcreate.bind(`${localStorage.getItem('client_id')}-customer-created-event`, data => {
            setRandom(Math.random())
        })

        channelupdate.bind(`${localStorage.getItem('client_id')}-customer-updated-event`, data => {
            setRandom(Math.random())
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-customer-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-customer-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-customer-updated`)
        })
    }, [])

    useEffect(() => {
        getTableView()
    }, [])

    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '13') {
                if (open) createCustomer()
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [
        open,
        no, name, address, address2, company, site, fax,
        phone, website, email, blocked, code, city, zip, state, country, creditTermId,
        generalCompanyEmail,
        salesContactFirstName, salesContactLastName,
        accountingContactFirstName, accountingContactLastName, accountingContactPhoneNo, accountingEmail,
        orderDeskContactFirstName, orderDeskContactLastName
    ])

    useEffect(() => {
        if (isValid(company_id)) {
            setCompany(company_id)
        } else {
            setCompany(localStorage.getItem('company_id'))
        }
    }, [company_id])

    useEffect(() => {
        getSites(company)
    }, [company])

    useEffect(() => {
        setSite('')
    }, [sites])

    useEffect(() => {
        getCustomers(pagination.blocked, pagination.no, pagination.name, pagination.page, pagination.rowsPerPage)
    }, [debouncedSearchNo, debouncedSearchName, debouncedSearchBlocked, pagination.page, pagination.rowsPerPage, random])

    useEffect(() => {
        createFilterArray(pagination.no, pagination.name, pagination.blocked)
    }, [debouncedSearchNo, debouncedSearchName, debouncedSearchBlocked])

    const getTableView = async() => {
        setIsLoading(true)
        await axios
            .get(`/api/get-table-view?view_name=customers_table&user_id=${user?.id}`, config)
            .then(res => {
                const model = res.data
                if (Object.keys(model?.success?.data).length > 0) {
                    setModel(model.success.data)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getCompanies = () => {
        if (user?.role === "office_manager") {
            getCompaniesContext()
            setCompany(user?.company_id)
        }
    }

    const getSites = async (id) => {
        setIsLoading(true)
        await axios
            .get(`/api/sites?company_id=${id}`, config)
            .then(res => {
                const sites = res.data
                setSites(sites)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getCustomers = async (id, no, name, page, page_size) => {
        setIsLoading(true)

        let url = buildUrl(
            '/api/customers',
            {
                where: {
                    'customer_no': no,
                    'name': name,
                    'blocked': id,
                    'page': page,
                    'page_size': page_size
                }
            }
        );

        await axios
            .get(url, config)
            .then(res => {
                const data = res.data
                setCustomers(data.data)
                setTotal(data.meta?.total)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const createCustomer = async () => {
        setIsLoading(true)
        const formData = {}

        formData['customer_no'] = no
        formData['name'] = name
        formData['address2'] = address2
        formData['address'] = address
        formData['company_id'] = company
        formData['location_id'] = site
        formData['fax'] = fax
        formData['phone'] = phone
        formData['website'] = website
        formData['email'] = email
        formData['blocked'] = blocked
        formData['shipping_code'] = code
        formData['city'] = city
        formData['state'] = state
        formData['zip'] = zip
        formData['country'] = country
        formData['credit_term_id'] = creditTermId

        formData['general_company_email'] = generalCompanyEmail
        formData['sales_contact_name'] = salesContactName
        formData['sales_contact_first_name'] = salesContactFirstName
        formData['sales_contact_last_name'] = salesContactLastName
        formData['accounting_contact_name'] = accountingContactName
        formData['accounting_contact_first_name'] = accountingContactFirstName
        formData['accounting_contact_last_name'] = accountingContactLastName
        formData['accounting_contact_phone_no'] = accountingContactPhoneNo
        formData['accounting_email'] = accountingEmail
        formData['order_desk_contact_name'] = orderDeskContactName
        formData['order_desk_contact_first_name'] = orderDeskContactFirstName
        formData['order_desk_contact_last_name'] = orderDeskContactLastName
        formData['order_desk_email'] = orderDeskEmail

        await axios
            .post(`/api/customers`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                handleClose()
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const columns = [
        {
            field: 'customer_no',
            headerName: t('no'),
            flex: 0.5
        },
        {
            field: 'name',
            headerName: t('name'),
            flex: 1
        },
        {
            field: 'email',
            headerName: t('email'),
            flex: 1
        },
        {
            field: 'address',
            headerName: t('address'),
            flex: 1
        },
        {
            field: 'zip',
            headerName: t('zip'),
            flex: 1,
            hide: true
        },
        {
            field: 'city',
            headerName: t('city'),
            flex: 1,
            hide: true
        },
        {
            field: 'state',
            headerName: t('state'),
            flex: 1,
            hide: true
        },
        {
            field: 'phone',
            headerName: t('phone'),
            flex: 1,
            hide: true
        },
        {
            field: 'fax',
            headerName: t('fax'),
            flex: 1,
            hide: true
        },
        {
            field: 'country',
            headerName: t('country'),
            flex: 1,
            hide: true
        },
        {
            field: 'website',
            headerName: t('website'),
            flex: 1,
            hide: true
        },
        {
            field: 'shipping_code',
            headerName: t('shipping_code'),
            flex: 1,
            hide: true
        },
        {
            field: 'company_name',
            headerName: t('company'),
            flex: 1
        },
        {
            field: 'location_name',
            headerName: t('location'),
            flex: 1
        },
        {
            field: 'blocked_text',
            headerName: t('blocked'),
            flex: 0.5
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.5,
            cellClassName: 'padding-0',
            renderCell: (params) => <CustomerActions params={params} customers={customers} setIsLoading={setIsLoading} companies={companies} getCompanies={getCompanies} getCustomers={getCustomers} config={config} />
        }
    ]

    /* HANDLERS */
    const handleOpen = () => {
        getCompanies()
        getSites(company)
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        setValue('1')
        setNo('')
        setName('')
        setAddress2('')
        setCompany(localStorage.getItem('company_id'))
        setAddress('')
        setZip('')
        setSite(' ')
        setFax('')
        setPhone('')
        setWebsite('')
        setEmail('')
        setBlocked(false)
        setCode(' ')
        setCity('')
        setState('')
        setCreditTermId('')
        setCreditTerm(null)
        setCreditTermInputValue('')
        setCountry(null)
        setCountryInputValue('')
        setGeneralCompanyEmail('')
        setSalesContactName('')
        setSalesContactFirstName('')
        setSalesContactLastName('')
        setAccountingContactName('')
        setAccountingContactFirstName('')
        setAccountingContactLastName('')
        setAccountingContactPhoneNo('')
        setAccountingEmail('')
        setOrderDeskContactName('')
        setOrderDeskContactFirstName('')
        setOrderDeskContactLastName('')
        setOrderDeskEmail('')
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }
    const handleBlocked = (event) => {
        setBlocked(event.target.checked);
    }

    const handleChangeBlocked = (event) => {
        setPagination((prev) => ({
            ...prev,
            blocked: event.target.value
        }))
    }
    const handleClearBlocked = () => {
        setPagination((prev) => ({
            ...prev,
            blocked: ''
        }))
    }
    const handleChangeSearchNo = (e) => {
        setPagination((prev) => ({
            ...prev,
            page: 1,
            no: e.target.value
        }))
    }
    const handleChangeSearchName = (e) => {
        setPagination((prev) => ({
            ...prev,
            page: 1,
            name: e.target.value
        }))
    }

    const handleClearSiteClick = () => {
        setSite('')
    }
    const handleClearCodeClick = () => {
        setCode('')
    }

    const handleChangeCreditTerm = (value) => {
        if(value) {
            setCreditTerm(value)
            setCreditTermId(value.id)
        } else {
            setCreditTerm(null)
            setCreditTermId('')
        }
    }

    const handlePagination = (newPage, newRowsPerPage) => {
        setPagination((prev) => ({
            ...prev,
            page: newPage,
            rowsPerPage: newRowsPerPage
        }));
    }

    const createFilterArray = (customerNo, customerName, customerBlocked) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].customer_no = customerNo
        filters[0].customer_name = customerName
        filters[0].customer_blocked = customerBlocked

        localStorage.setItem('filters', JSON.stringify(filters))
    }

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout showFooter={!open}>
                <div className='p-5 pt-0'>
                    <div className='pb-5 bg-white mb-2 rounded-md'>
                        <div className='flex justify-between p-5 items-center border-b'>
                            <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('customers_list')}</p>
                            <Tooltip disableInteractive title={t('create_customer')} placement='bottom'>
                                <div>
                                    <AddButton onClick={handleOpen}><i className="fa-solid fa-plus"></i></AddButton>
                                </div>
                            </Tooltip>
                        </div>
                        <div className='flex justify-between items-end w-full border-b pb-5'>
                            <div className='px-5 pt-5 w-full'>
                                <div className='flex justify-between items-center search'>
                                    <input
                                        type="text"
                                        placeholder={t('search_by_customer_no')}
                                        value={pagination.no}
                                        className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place'
                                        onChange={(e) => handleChangeSearchNo(e)}
                                    />
                                    <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                </div>
                            </div>
                            <div className='pt-5 w-full'>
                                <div className='flex justify-between items-center search'>
                                    <input
                                        type="text"
                                        placeholder={t('search_by_name')}
                                        value={pagination.name}
                                        className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place'
                                        onChange={(e) => handleChangeSearchName(e)}
                                    />
                                    <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                </div>
                            </div>
                            <div className='px-5 pt-5 w-full'>
                                <FormControl sx={{ width: 'inherit' }}>
                                    <InputLabel id="demo-simple-select-standard-label">{t('search_by_blocked')}</InputLabel>
                                    <Select
                                        value={pagination.blocked}
                                        onChange={handleChangeBlocked}
                                        label="Search role"
                                        sx={{
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                            ".MuiSelect-iconOutlined": { display: pagination.blocked ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                        }}
                                        endAdornment={pagination.blocked ? (<IconButton sx={{ visibility: pagination.blocked ? "visible" : "hidden", padding: '0' }} onClick={handleClearBlocked}><ClearIcon /></IconButton>) : false}
                                    >
                                        <MenuItem value="1">{t('yes')}</MenuItem>
                                        <MenuItem value="0">{t('negatory')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className='pt-3'>
                            <TableSharedPaginated
                                pagination={pagination}
                                handlePagination={handlePagination}
                                total={total}
                                items={customers}
                                columns={columns}
                                columnVisibilityModel={model}
                                setModel={setModel}
                                view='customers'
                            />
                        </div>
                    </div>

                </div>
            </AppLayout>


            <Modal open={open} onClose={handleClose}>
                <Box sx={{...style, overflowX: 'hidden'}}>
                    <div className='flex border-b justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                        <div className='flex items-center justify-between w-full leading-none'>
                            <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#336195] text-[35px] mr-4'>•</span> {t('create_customer')}</p>
                            <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                                <button onClick={handleClose}><i className="fa-solid fa-plus"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className='w-full bg-white h-auto min-h-full'>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example" variant='scrollable'>
                                    <Tab sx={{ textTransform: 'none' }} value="1" label={t('general')} icon={<i className="fa-solid fa-circle-info"></i>} iconPosition='start' />
                                    <Tab sx={{ textTransform: 'none' }} value="2" label={t('communication')} icon={<i className="fa-solid fa-phone"></i>} iconPosition='start' />
                                    <Tab sx={{ textTransform: 'none' }} value="3" label={t('shipping')} icon={<i className="fa-solid fa-truck"></i>} iconPosition='start' />
                                    <CloneProps>
                                        {tabProps => (
                                            <Tooltip disableInteractive title={true ? t('define_customer_sa') : ""} arrow>
                                                <div>
                                                    <Tab sx={{ textTransform: 'none' }} value="4" disabled label={t('ship_to_address')} icon={<i className="fa-solid fa-address-book"></i>} iconPosition='start' />
                                                </div>
                                            </Tooltip>
                                        )}
                                    </CloneProps>
                                    <CloneProps>
                                        {tabProps => (
                                            <Tooltip disableInteractive title={true ? t('define_customer_sc') : ""} arrow>
                                                <div>
                                                    <Tab sx={{ textTransform: 'none' }} value="5" disabled label={t('sales_prices')} icon={<i className="fa-solid fa-money-check-dollar"></i>} iconPosition='start' />
                                                </div>
                                            </Tooltip>
                                        )}
                                    </CloneProps>
                                    <CloneProps>
                                        {tabProps => (
                                            <Tooltip disableInteractive title={true ? t('define_customer_insights') : ""} arrow>
                                                <div>
                                                    <Tab sx={{textTransform: 'none'}} value="6" disabled
                                                         label={t('customer_users')}
                                                         icon={<i className="fa-solid fa-users-rays fa-lg"></i>}
                                                         iconPosition='start'/>
                                                </div>
                                            </Tooltip>
                                        )}
                                    </CloneProps>


                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <div>
                                    <form className='flex justify-center flex-col items-start mt-2'>
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('no')} *</p>
                                            <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                name="new-customer_no"
                                                value={no}
                                                onChange={(e) => { setNo(e.target.value) }}
                                            />
                                        </div>
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('name')} *</p>
                                            <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                name="new-customer_name"
                                                value={name}
                                                onChange={(e) => { setName(e.target.value) }}
                                            />
                                        </div>
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('address')} *</p>
                                            <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                name="new-address"
                                                value={address}
                                                onChange={(e) => { setAddress(e.target.value) }}
                                            />
                                        </div>
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('address2')}</p>
                                            <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                name="new-address2"
                                                value={address2}
                                                onChange={(e) => { setAddress2(e.target.value) }}
                                            />
                                        </div>
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('city')} *</p>
                                            <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                name="new-city"
                                                value={city}
                                                onChange={(e) => { setCity(e.target.value) }}
                                            />
                                        </div>
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('state')} *</p>
                                            <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                name="new-state"
                                                value={state}
                                                onChange={(e) => { setState(e.target.value) }}
                                            />
                                        </div>
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('zip')} * </p>
                                            <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                name="new-zip"
                                                value={zip}
                                                onChange={(e) => { setZip(e.target.value) }}
                                            />
                                        </div>
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('country')}</p>
                                            <Autocomplete
                                                disablePortal
                                                id="countries-autocomplete"
                                                options={countries.map(({ name }) => name)}
                                                value={country}
                                                onChange={(a, b) => setCountry(b) }
                                                inputValue={countryInputValue}
                                                onInputChange={(e, value) => setCountryInputValue(value)}
                                                renderInput={(params) => <TextField {...params} /> }
                                                sx={AUTOCOMPLETE_STYLE}
                                            />
                                        </div>

                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('company')} *</p>
                                            <FormControl sx={{ width: '100%' }}>
                                                <Select
                                                    value={company}
                                                    onChange={(e) => { setCompany(e.target.value) }}
                                                    sx={SELECT_STYLE}
                                                >
                                                    {companies.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </div>

                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('credit_term')}</p>
                                            <Autocomplete
                                                disablePortal
                                                id="credit-terms-autocomplete"
                                                options={creditTerms}
                                                getOptionLabel={(option) => option.name || ''}
                                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                value={creditTerm}
                                                onChange={(a, b) => handleChangeCreditTerm(b) }
                                                inputValue={creditTermInputValue}
                                                onInputChange={(e, value) => setCreditTermInputValue(value)}
                                                renderInput={(params) => <TextField {...params} /> }
                                                sx={AUTOCOMPLETE_STYLE}
                                            />
                                        </div>

                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('blocked')} </p>
                                            <Switch checked={blocked} onChange={handleBlocked} inputProps={{ 'aria-label': 'controlled' }} />
                                        </div>
                                    </form>
                                </div>
                            </TabPanel>
                            <TabPanel value="3">
                                <div>
                                    <form className='flex justify-center flex-col items-start mt-2'>
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('default_site')}</p>
                                            <FormControl sx={{ width: '100%' }}>
                                                <Select
                                                    value={site}
                                                    onChange={(e) => { setSite(e.target.value) }}
                                                    sx={{
                                                        ...SELECT_STYLE,
                                                        ".MuiSelect-iconOutlined": {
                                                            display: site ? 'none !important' : ''
                                                        }
                                                    }}
                                                    endAdornment={site ? (<IconButton sx={{ visibility: site ? "visible" : "hidden", padding: '0' }} onClick={handleClearSiteClick}><ClearIcon /></IconButton>) : false}
                                                >
                                                    {sites?.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </div>


                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('shipping_code')}</p>
                                            <FormControl sx={{ width: '100%' }}>
                                                <Select
                                                    value={code}
                                                    onChange={(e) => { setCode(e.target.value) }}
                                                    sx={{
                                                        ...SELECT_STYLE,
                                                        ".MuiSelect-iconOutlined": {
                                                            display: code ? 'none !important' : ''
                                                        }
                                                    }}
                                                    endAdornment={code ? (<IconButton sx={{ visibility: code ? "visible" : "hidden", padding: '0' }} onClick={handleClearCodeClick}><ClearIcon /></IconButton>) : false}
                                                >
                                                    <MenuItem value="CPU">CUSTOMER PICK UP</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </form>
                                </div>
                            </TabPanel>
                            <TabPanel value="2">
                                <div>
                                    <form className='flex justify-center flex-col items-start mt-2'>
                                        {/* General Company Email */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('general_company_email')}</p>
                                            <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                name="new-general_company_email"
                                                value={generalCompanyEmail}
                                                onChange={(e) => { setGeneralCompanyEmail(e.target.value) }}
                                            />
                                        </div>

                                        {/* Sales Contact Name */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('sales_contact_name')}</p>
                                            <Stack direction='row' gap={1}>
                                                <TextField
                                                    sx={TEXTFIELD_STYLE}
                                                    type="text"
                                                    className='grow'
                                                    name="new-sales_contact_first_name"
                                                    value={salesContactFirstName}
                                                    placeholder={t('first_name')}
                                                    onChange={(e) => { setSalesContactFirstName(e.target.value) }}
                                                />

                                                <TextField
                                                    sx={TEXTFIELD_STYLE}
                                                    type="text"
                                                    className='grow'
                                                    name="new-sales_contact_last_name"
                                                    value={salesContactLastName}
                                                    placeholder={t('last_name')}
                                                    onChange={(e) => { setSalesContactLastName(e.target.value) }}
                                                />
                                            </Stack>
                                        </div>

                                        {/* Sales Contact Email */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('sales_contact_email')}</p>
                                            <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                name="new-sales_contact_email"
                                                value={email}
                                                onChange={(e) => { setEmail(e.target.value) }}
                                            />
                                        </div>

                                        {/* Sales Contact Phone No. */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('sales_contact_phone_no')}</p>
                                            <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                name="new-sales_contact_phone_no"
                                                value={phone}
                                                onChange={(e) => { setPhone(e.target.value) }}
                                            />
                                        </div>

                                        {/* Accounting Contact Name */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('accounting_contact_name')}</p>
                                            <Stack direction='row' gap={1}>
                                                <TextField
                                                    sx={TEXTFIELD_STYLE}
                                                    type="text"
                                                    className='grow'
                                                    name="new-accounting_contact_first_name"
                                                    value={accountingContactFirstName}
                                                    placeholder={t('first_name')}
                                                    onChange={(e) => { setAccountingContactFirstName(e.target.value) }}
                                                />

                                                <TextField
                                                    sx={TEXTFIELD_STYLE}
                                                    type="text"
                                                    className='grow'
                                                    name="new-accounting_contact_last_name"
                                                    value={accountingContactLastName}
                                                    placeholder={t('last_name')}
                                                    onChange={(e) => { setAccountingContactLastName(e.target.value) }}
                                                />
                                            </Stack>
                                        </div>

                                        {/* Accounting Contact Phone No. */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('accounting_contact_phone_no')}</p>
                                            <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                name="new-accounting_contact_phone_no"
                                                value={accountingContactPhoneNo}
                                                onChange={(e) => { setAccountingContactPhoneNo(e.target.value) }}
                                            />
                                        </div>

                                        {/* Accounting Email */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('accounting_email')}</p>
                                            <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                name="new-accounting_email"
                                                value={accountingEmail}
                                                onChange={(e) => { setAccountingEmail(e.target.value) }}
                                            />
                                        </div>

                                        {/* Order Desk Contact Name */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('order_desk_contact_name')}</p>
                                            <Stack direction='row' gap={1}>
                                                <TextField
                                                    sx={TEXTFIELD_STYLE}
                                                    type="text"
                                                    className='grow'
                                                    name="new-order_desk_contact_first_name"
                                                    value={orderDeskContactFirstName}
                                                    placeholder={t('first_name')}
                                                    onChange={(e) => { setOrderDeskContactFirstName(e.target.value) }}
                                                />

                                                <TextField
                                                    sx={TEXTFIELD_STYLE}
                                                    type="text"
                                                    className='grow'
                                                    name="new-order_desk_contact_last_name"
                                                    value={orderDeskContactLastName}
                                                    placeholder={t('last_name')}
                                                    onChange={(e) => { setOrderDeskContactLastName(e.target.value) }}
                                                />
                                            </Stack>
                                        </div>

                                        {/* Order Desk Email */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('order_desk_email')}</p>
                                            <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                name="new-order_desk_email"
                                                value={orderDeskEmail}
                                                onChange={(e) => { setOrderDeskEmail(e.target.value) }}
                                            />
                                        </div>

                                        {/* Fax */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('fax')} </p>
                                            <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                name="new-fax"
                                                value={fax}
                                                onChange={(e) => { setFax(e.target.value) }}
                                            />
                                        </div>

                                        {/* Website */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('website')}</p>
                                            <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                name="new-website"
                                                value={website}
                                                onChange={(e) => { setWebsite(e.target.value) }}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </TabPanel>
                            <TabPanel value="4">
                            </TabPanel>
                            <TabPanel value="5">
                            </TabPanel>
                        </TabContext>
                        <div className='w-full flex justify-end px-6 pb-14'>
                            <button type="button" onClick={createCustomer} className=' text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#DBA34B] hover:bg-[#CF7300] disabled:bg-[#B8B7BC]'>
                                <span className='pr-2'><i className="fa-solid fa-plus"></i></span> {t('create')}
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default Customers


const CustomerActions = (params) => {

    const { t } = useTranslation();

    const deleteCustomers = async (id) => {
        const isConfirm = await Swal.fire({
            title: t('title_delete') + t('del_cus') + params.params.row.customer_no + "?",
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,

            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            return result.isConfirmed
        })

        if (!isConfirm) {
            return
        }
        await axios.delete(`/api/customers/${id}`, params.config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
        }).catch(({ response: { data } }) => {
            Swal.fire({
                text: data.message,
                icon: "error",
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000'
            })
        })
    }



    return (
        <>
            <div className='flex justify-between'>
                <Tooltip disableInteractive title={t('edit')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <Link to={`/customers/${params.params.row.id}`}><span style={{ cursor: 'pointer' }}
                            className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i
                                className="fa-solid fa-pencil"></i></span></Link>
                    </div>
                </Tooltip>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => deleteCustomers(params.params.row.id)}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}

function CloneProps(props) {
    const { children, ...other } = props;
    return children(other);
}
